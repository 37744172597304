a:link,
a:visited {
    color: #399AF2
}

a.default-color,
a.default-color:link,
a.default-color:visited {
    color: #617182
}

a.default-color:active,
a.default-color:hover,
a:active,
a:hover {
    color: #5babf4
}

a.accent:link,
a.accent:visited {
    color: #F64A91
}

a.accent:active,
a.accent:hover {
    color: #f53282
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #4d5a68
}

p {
    color: #617182
}

hr {
    border-top: 1px solid #f0f6ff
}

hr.dashed {
    border-top: 1px dashed #d7e7ff
}

hr.thick {
    border-width: 2px
}

hr.thicker {
    border-width: 3px
}

.brand-primary {
    background-color: #399AF2 !important
}

.brand-accent {
    background-color: #F64A91 !important
}

.bg-primary {
    background-color: #399AF2 !important
}

.bg-primary .bg-lt,
.bg-primary.bg-lt {
    background-color: #51a6f4 !important
}

.bg-primary .bg-dk,
.bg-primary.bg-lt {
    background-color: #218ef0 !important
}

a.bg-primary:focus,
a.bg-primary:hover {
    background-color: #0062cc !important
}

.bg-accent {
    background-color: #F64A91 !important
}

.bg-accent .bg-lt,
.bg-accent.bg-lt {
    background-color: #f762a0 !important
}

.bg-accent .bg-dk,
.bg-accent.bg-lt {
    background-color: #f53282 !important
}

a.bg-accent:focus,
a.bg-accent:hover {
    background-color: #0062cc !important
}

.text-primary {
    color: #399AF2 !important
}

.text-accent {
    color: #F64A91 !important
}

.swatch.swatch-color_primary {
    background-color: #399AF2
}

.swatch.swatch-color_accent {
    background-color: #F64A91
}

body {
    background-color: #f0f6ff;
    color: #617182
}

body .top-toolbar.navbar-desktop {
    background: #399AF2
}

body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a,
body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a i,
body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a:hover,
body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a:hover i {
    color: #fff
}

body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a:hover i.icon:after {
    background-color: rgba(255, 255, 255, .1)
}

body .top-toolbar.navbar-desktop .dropdown-menu ul li:hover {
    color: #399AF2 !important
}

body .top-toolbar.navbar-desktop .dropdown-menu ul li:hover a {
    color: #399AF2
}

body .top-toolbar.navbar-desktop .dropdown-menu ul li a {
    color: #617182;
    display: block
}

@media (max-width:992px) {
    body .top-toolbar.navbar-desktop {
        background-color: #fff
    }

    body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a,
    body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a>i {
        color: #718EB1
    }

    body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a:active,
    body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a:active i,
    body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a:hover,
    body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a:hover i {
        color: #399AF2
    }

    body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a:active i.icon:after,
    body .top-toolbar.navbar-desktop .navbar-nav .nav-item>a:hover i.icon:after {
        background-color: rgba(255, 255, 255, .1)
    }

    body .top-toolbar.navbar-desktop .dropdown-menu ul li:hover {
        color: #399AF2 !important
    }

    body .top-toolbar.navbar-desktop .dropdown-menu ul li:hover a {
        color: #399AF2
    }

    body .top-toolbar.navbar-desktop .dropdown-menu ul li a {
        color: #617182;
        display: block
    }
}

body .aside-toolbar,
body .top-toolbar.navbar-mobile.navbar-tablet {
    background-color: #399AF2
}

body .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item>a {
    color: #fff
}

body .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item>a:active,
body .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item>a:focus,
body .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item>a:hover,
body .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item>a>i,
body .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item>a>i:active,
body .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item>a>i:focus,
body .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item>a>i:hover {
    color: #fff !important
}

body .brand-text,
body .header-controls .btn-menu i,
body .header-controls .btn-menu:hover i {
    color: #fff
}

body .site-logo .logo #logo_path,
body .site-logo .logo_mobile #logo_path {
    fill: url(#logo_background_color)
}

body .site-logo .logo #logo_path_mobile,
body .site-logo .logo_mobile #logo_path_mobile {
    fill: url(#logo_background_mobile_color)
}

body .site-logo .logo .stop1,
body .site-logo .logo .stop2,
body .site-logo .logo .stop3,
body .site-logo .logo_mobile .stop1,
body .site-logo .logo_mobile .stop2,
body .site-logo .logo_mobile .stop3 {
    stop-color: #fff;
    stop-opacity: 1
}

body .sidebar-left,
body .sidebar-left .sidebar-content {
    background-color: #fff
}

body .sidebar.sidebar-left .sidebar-content .main-menu .sidebar-header {
    color: rgba(113, 142, 177, .6)
}

body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.nav-dropdown>ul>li.nav-dropdown>ul>li>a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.nav-dropdown>ul>li.nav-dropdown>ul>li>a>i,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li>a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li>a > span > i,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li>a>i {
    color: #718EB1
}

body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu li a:hover,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu li a:hover i {
    color: #399AF2 !important
}

body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open>a,
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open>a:focus,
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open>a:hover,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.active>a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.active>a>i,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.nav-dropdown.active>ul>li.nav-dropdown.active>ul>li.active>a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.nav-dropdown>ul>li.nav-dropdown>ul>li>a:hover,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li>a:active,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li>a:hover {
    color: #399AF2;
    background-color: transparent
}

body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu>li>a:focus {
    background-color: #fff
}

body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu>li>a:hover {
    background-color: #399AF2
}

body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .nav-sub {
    background-color: transparent
}

body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .nav-sub li>a,
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .nav-sub li>a>i {
    color: #718EB1
}

body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .nav-sub li a:hover,
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu>li>.nav-sub li a:hover,
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu>li>.nav-sub li.active a,
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu>li>.nav-sub li.active a:hover {
    color: #399AF2
}

body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.active>a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.active>a:focus,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.active>a:hover {
    color: #399AF2;
    background-color: transparent;
    background-size: 3px 100%;
    border-left: solid 3px #399AF2
}

body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>.nav-dropdown.active>a {
    background-color: transparent;
    background-size: 3px 100%;
    border-left: solid 3px #399AF2
}

body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>.nav-dropdown.active>.nav-sub {
    background: linear-gradient(180deg, #399AF2 25%, #399AF2 50%, #399AF2 75%, #399AF2 100%) left bottom no-repeat #fff;
    background-size: 3px 100%
}

body.compact-menu .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li:hover>a,
body.compact-menu .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li:hover>a>i {
    color: #fff
}

body.compact-menu .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li:hover>ul {
    background: #fff !important;
    border-left: solid thin #0a0c19
}

body.compact-menu .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li:hover>ul li a {
    color: #718EB1
}

.alert.alert-primary,
body.compact-menu .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.active ul li.active>a,
body.compact-menu .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li:hover>ul li a:hover {
    color: #fff
}

body.compact-menu .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.active>a {
    background: #399AF2 !important;
    color: #fff
}

@media (max-width:991.98px) {
    body.overlay-menu .brand-text {
        color: #fff
    }
}

body.content-menu .sidebar.sidebar-left {
    background: #fff !important
}

body.content-menu .sidebar.sidebar-left .sidebar-content {
    background: 0 0
}

.alert.alert-primary {
    background-color: #399AF2;
    border-color: #399AF2
}

.alert.alert-primary.alert-outline {
    background-color: #fff;
    color: #399AF2
}

.alert.alert-primary.alert-outline .alert-link {
    color: #399AF2;
    border-bottom: dotted 1px rgba(57, 154, 242, .85)
}

.alert.alert-primary.alert-outline .alert-link:hover {
    color: #399af2;
    border-bottom: none
}

.alert.alert-primary.alert-outline .icon i {
    color: #399AF2
}

.alert.alert-primary.alert-outline.alert-dismissible .close {
    color: rgba(57, 154, 242, .85)
}

.alert.alert-primary.alert-outline.alert-dismissible .close:hover {
    color: #399af2
}

.alert.alert-primary.alert-outline.alert-dismissible .close:hover:before {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgba(57, 154, 242, .125);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 11px
}

.alert.alert-accent {
    background-color: #F64A91;
    border-color: #F64A91;
    color: #fff
}

.alert.alert-accent.alert-outline {
    background-color: #fff;
    color: #F64A91
}

.alert.alert-accent.alert-outline .alert-link {
    color: #F64A91;
    border-bottom: dotted 1px rgba(246, 74, 145, .85)
}

.alert.alert-accent.alert-outline .alert-link:hover {
    color: #f64a91;
    border-bottom: none
}

.alert.alert-accent.alert-outline .icon i {
    color: #F64A91
}

.alert.alert-accent.alert-outline.alert-dismissible .close {
    color: rgba(246, 74, 145, .85)
}

.alert.alert-accent.alert-outline.alert-dismissible .close:hover {
    color: #f64a91
}

.alert.alert-accent.alert-outline.alert-dismissible .close:hover:before {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgba(246, 74, 145, .125);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 11px
}

.badge.badge-primary {
    background-color: #399AF2;
    color: rgba(255, 255, 255, .85)
}

.badge.badge-primary[href]:focus,
.badge.badge-primary[href]:hover {
    text-decoration: none;
    background-color: #51a6f4 !important;
    color: #fff
}

.badge.badge-accent {
    background-color: #F64A91;
    color: rgba(255, 255, 255, .85)
}

.badge.badge-accent[href]:focus,
.badge.badge-accent[href]:hover {
    text-decoration: none;
    background-color: #f762a0 !important;
    color: #fff
}

.btn-primary,
a.btn-primary,
a:visited.btn-primary a:link.btn-primary {
    background-color: #399AF2;
    border: 1px solid #399AF2;
    color: rgba(255, 255, 255, .9)
}

.btn-primary:hover,
a.btn-primary:hover,
a:visited.btn-primary a:link.btn-primary:hover {
    background-color: #2b93f1 !important;
    border: 1px solid #2b93f1 !important;
    box-shadow: 0 5px 10px rgba(57, 154, 242, .4) !important;
    color: #fff
}

.btn-primary:active,
a.btn-primary:active,
a:visited.btn-primary a:link.btn-primary:active {
    background-color: #3e9cf2 !important;
    border: 1px solid #3e9cf2 !important;
    color: #fff !important;
    box-shadow: none !important
}

.btn-primary:focus,
a.btn-primary:focus,
a:visited.btn-primary a:link.btn-primary:focus {
    background-color: #399AF2;
    border: 1px solid #399AF2;
    color: #fff !important;
    box-shadow: none !important
}

.btn-primary.btn-floating,
a.btn-primary.btn-floating,
a:visited.btn-primary a:link.btn-primary.btn-floating {
    box-shadow: 0 5px 10px rgba(57, 154, 242, .4) !important
}

.btn-primary.btn-floating:active,
.btn-primary.btn-floating:hover,
a.btn-primary.btn-floating:active,
a.btn-primary.btn-floating:hover,
a:visited.btn-primary a:link.btn-primary.btn-floating:active,
a:visited.btn-primary a:link.btn-primary.btn-floating:hover {
    box-shadow: 0 15px 20px rgba(57, 154, 242, .4) !important
}

.btn-primary.btn-outline,
a.btn-primary.btn-outline,
a:visited.btn-primary a:link.btn-primary.btn-outline {
    background-color: #fff;
    color: #399AF2;
    border: 1px solid #399AF2
}

.btn-primary.btn-outline:focus,
a.btn-primary.btn-outline:focus,
a:visited.btn-primary a:link.btn-primary.btn-outline:focus {
    background-color: #fff !important;
    color: #399AF2 !important
}

.btn-primary.btn-outline:active,
.btn-primary.btn-outline:hover,
a.btn-primary.btn-outline:active,
a.btn-primary.btn-outline:hover,
a:visited.btn-primary a:link.btn-primary.btn-outline:active,
a:visited.btn-primary a:link.btn-primary.btn-outline:hover {
    color: #fff !important;
    background-color: #399AF2 !important
}

.btn-primary.disabled,
.btn-primary:disabled {
    cursor: not-allowed;
    color: #000000;
    background-color: #D3D3D3;
    border-color: #D3D3D3
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: #399AF2;
    border: 1px solid #399AF2;
    color: rgba(255, 255, 255, .9)
}

.btn-accent,
a.btn-accent,
a:link.btn-accent,
a:visited.btn-accent {
    background-color: #F64A91;
    border: 1px solid #F64A91;
    color: rgba(255, 255, 255, .9)
}

.btn-accent:hover,
a.btn-accent:hover,
a:link.btn-accent:hover,
a:visited.btn-accent:hover {
    background-color: #f53b88 !important;
    border: 1px solid #f53b88 !important;
    box-shadow: 0 5px 10px rgba(246, 74, 145, .4) !important;
    color: #fff
}

.btn-accent:active,
a.btn-accent:active,
a:link.btn-accent:active,
a:visited.btn-accent:active {
    background-color: #f64f94 !important;
    border: 1px solid #f64f94 !important;
    color: #fff !important;
    box-shadow: none !important
}

.btn-accent:focus,
a.btn-accent:focus,
a:link.btn-accent:focus,
a:visited.btn-accent:focus {
    background-color: #F64A91;
    border: 1px solid #F64A91;
    color: #fff !important;
    box-shadow: none !important
}

.btn-accent.btn-floating,
a.btn-accent.btn-floating,
a:link.btn-accent.btn-floating,
a:visited.btn-accent.btn-floating {
    box-shadow: 0 5px 10px rgba(246, 74, 145, .4) !important
}

.btn-accent.btn-floating:active,
.btn-accent.btn-floating:focus,
.btn-accent.btn-floating:hover,
a.btn-accent.btn-floating:active,
a.btn-accent.btn-floating:focus,
a.btn-accent.btn-floating:hover,
a:link.btn-accent.btn-floating:active,
a:link.btn-accent.btn-floating:focus,
a:link.btn-accent.btn-floating:hover,
a:visited.btn-accent.btn-floating:active,
a:visited.btn-accent.btn-floating:focus,
a:visited.btn-accent.btn-floating:hover {
    box-shadow: 0 15px 20px rgba(246, 74, 145, .4) !important
}

.btn-accent.btn-outline,
a.btn-accent.btn-outline,
a:link.btn-accent.btn-outline,
a:visited.btn-accent.btn-outline {
    background-color: #fff;
    color: #F64A91
}

.btn-accent.btn-outline:focus,
a.btn-accent.btn-outline:focus,
a:link.btn-accent.btn-outline:focus,
a:visited.btn-accent.btn-outline:focus {
    background-color: #fff !important;
    color: #F64A91 !important
}

.btn-accent.btn-outline:active,
.btn-accent.btn-outline:hover,
a.btn-accent.btn-outline:active,
a.btn-accent.btn-outline:hover,
a:link.btn-accent.btn-outline:active,
a:link.btn-accent.btn-outline:hover,
a:visited.btn-accent.btn-outline:active,
a:visited.btn-accent.btn-outline:hover {
    color: #fff !important;
    background-color: #F64A91 !important
}

.btn-accent.disabled,
.btn-accent:disabled {
    color: #fff;
    background-color: rgba(246, 74, 145, .85);
    border-color: rgba(246, 74, 145, .85)
}

.btn-accent:not([disabled]):not(.disabled).active,
.btn-accent:not([disabled]):not(.disabled):active,
.show>.btn-accent.dropdown-toggle {
    background-color: #F64A91;
    border: 1px solid #F64A91;
    color: rgba(255, 255, 255, .9)
}

.btn.btn-icon i {
    font-size: 1.25rem
}

.btn.btn-icon.btn-accent i,
.btn.btn-icon.btn-primary i,
.btn.btn-icon.btn-secondary i {
    color: #fff
}

.icon-rounded {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    text-align: center
}

.dropdown.show .dropdown-icon.dropdown-accent:before,
.dropdown.show .dropdown-icon.dropdown-primary:before {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    left: 0;
    top: -2px
}

.icon-rounded i {
    font-size: 24px;
    padding-top: 12px
}

.icon-rounded.icon-rounded-primary {
    background-color: rgba(57, 154, 242, .4)
}

.icon-rounded.icon-rounded-primary i {
    color: #399AF2
}

.icon-rounded.icon-rounded-accent {
    background-color: rgba(246, 74, 145, .4)
}

.icon-rounded.icon-rounded-accent i {
    color: #F64A91
}

.list-group-item-primary {
    color: #e1effd;
    background-color: #399AF2
}

.list-group-item-accent {
    color: #fef4f8;
    background-color: #F64A91
}

.dropdown .dropdown-menu .dropdown-header,
.dropleft .dropdown-menu .dropdown-header,
.dropright .dropdown-menu .dropdown-header,
.dropup .dropdown-menu .dropdown-header {
    color: #4d5a68;
    border-bottom: 1px solid rgba(234, 243, 253, .7)
}

.dropdown .dropdown-menu .dropdown-item,
.dropleft .dropdown-menu .dropdown-item,
.dropright .dropdown-menu .dropdown-item,
.dropup .dropdown-menu .dropdown-item {
    color: #839bb3
}

.dropdown .dropdown-menu .dropdown-item .dropdown-item i,
.dropleft .dropdown-menu .dropdown-item .dropdown-item i,
.dropright .dropdown-menu .dropdown-item .dropdown-item i,
.dropup .dropdown-menu .dropdown-item .dropdown-item i {
    padding-right: .9375rem
}

.nav-pills.nav-pills-accent .nav-link.btn-floating,
.nav-pills.nav-pills-primary .nav-link.btn-floating {
    padding: 10px 25px;
    border-radius: 50px
}

.dropdown .dropdown-menu .dropdown-item.active,
.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item.active,
.dropleft .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:focus,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item.active,
.dropright .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:focus,
.dropright .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item.active,
.dropup .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:focus,
.dropup .dropdown-menu .dropdown-item:hover {
    background-color: rgba(231, 241, 253, .2);
    color: #399AF2 !important
}

.dropdown .dropdown-menu .dropdown-item.active i,
.dropdown .dropdown-menu .dropdown-item:active i,
.dropdown .dropdown-menu .dropdown-item:focus i,
.dropdown .dropdown-menu .dropdown-item:hover i,
.dropleft .dropdown-menu .dropdown-item.active i,
.dropleft .dropdown-menu .dropdown-item:active i,
.dropleft .dropdown-menu .dropdown-item:focus i,
.dropleft .dropdown-menu .dropdown-item:hover i,
.dropright .dropdown-menu .dropdown-item.active i,
.dropright .dropdown-menu .dropdown-item:active i,
.dropright .dropdown-menu .dropdown-item:focus i,
.dropright .dropdown-menu .dropdown-item:hover i,
.dropup .dropdown-menu .dropdown-item.active i,
.dropup .dropdown-menu .dropdown-item:active i,
.dropup .dropdown-menu .dropdown-item:focus i,
.dropup .dropdown-menu .dropdown-item:hover i {
    color: #399AF2
}

.page-header .actions>.dropdown>a {
    border-color: transparent !important;
    background-color: #fff
}

.page-header .actions>.dropdown>a:active,
.page-header .actions>.dropdown>a:hover {
    background-color: #399AF2 !important;
    color: #fff
}

.page-header .actions>.dropdown.show>a {
    background-color: #399AF2;
    color: #fff
}

.page-header .actions.open>a>i,
.page-header .actions>.dropdown.show>a>i,
.page-header .actions>a:hover>i,
.page-header .actions>li.open>a>i,
.page-header .actions>li>a:hover>i {
    color: #fff
}

.dropdown .dropdown-icon {
    text-align: center;
    vertical-align: middle
}

.dropdown.show .dropdown-icon.dropdown-primary i {
    color: #399AF2
}

.dropdown.show .dropdown-icon.dropdown-primary:before {
    background-color: rgba(57, 154, 242, .4)
}

.dropdown.show .dropdown-icon.dropdown-accent i {
    color: #F64A91
}

.tile-accent:hover,
.tile-accent:hover i,
.tile-primary:hover,
.tile-primary:hover i {
    color: #fff !important
}

.dropdown.show .dropdown-icon.dropdown-accent:before {
    background-color: rgba(246, 74, 145, .4)
}

.tile-primary:hover {
    background-color: #399AF2 !important;
    box-shadow: 0 15px 20px rgba(57, 154, 242, .4) !important
}

.tile-accent:hover {
    background-color: #F64A91 !important;
    box-shadow: 0 15px 20px rgba(246, 74, 145, .4) !important
}

.nav .nav-item .nav-link.active,
.nav .nav-item .nav-link:active,
.nav .nav-item .nav-link:hover,
.nav-tabs.primary-tabs .nav-item .nav-link.active.show,
.nav-tabs.primary-tabs .nav-item .nav-link:hover,
.nav-tabs.primary-tabs .nav-item.show .nav-link {
    color: #399AF2
}

.nav-tabs.primary-tabs .nav-item .nav-link.active {
    border-bottom: solid 1px #399AF2;
    color: #399AF2
}

.nav-tabs.primary-tabs .nav-item.show .nav-link,
.nav-tabs.primary-tabs .nav-link.active {
    border-color: #399AF2
}

.nav-tabs.accent-tabs .nav-item .nav-link.active {
    border-bottom: solid 1px #F64A91;
    color: #F64A91
}

.nav-tabs.accent-tabs .nav-item .nav-link.active.show,
.nav-tabs.accent-tabs .nav-item .nav-link:hover,
.nav-tabs.accent-tabs .nav-item.show .nav-link {
    color: #F64A91
}

.nav-tabs.accent-tabs .nav-item.show .nav-link,
.nav-tabs.accent-tabs .nav-link.active {
    border-color: #F64A91
}

.nav-pills .nav-link {
    color: #788db4;
    border-radius: 50px;
    transition: all 250ms ease
}

.nav-pills .nav-link.active,
.nav-pills .nav-link:hover {
    color: #399AF2
}

.nav-pills.nav-pills-accent .nav-item .nav-link.active,
.nav-pills.nav-pills-primary .nav-item .nav-link.active {
    color: #fff
}

.nav-pills.nav-pills-primary .nav-link.active,
.nav-pills.nav-pills-primary .show>.nav-link {
    background-color: #399AF2
}

.nav-pills.nav-pills-primary .nav-link.btn-floating.active {
    box-shadow: 0 5px 10px rgba(57, 154, 242, .4) !important
}

.nav-pills.nav-pills-accent .nav-link.active,
.nav-pills.nav-pills-accent .show>.nav-link {
    background-color: #F64A91
}

.nav-pills.nav-pills-accent .nav-link.btn-floating.active {
    box-shadow: 0 5px 10px rgba(246, 74, 145, .4) !important
}

.pace .pace-progress {
    background-color: #399AF2
}

.progress {
    background-color: #f0f6ff
}

.progress-bar {
    background-color: #399AF2
}

.timeline-primary {
    border-color: #399AF2 !important
}

.timeline-primary:after {
    box-shadow: 0 0 0 3px #399AF2 inset !important
}

.timeline-accent {
    border-color: #F64A91 !important
}

.timeline-accent:after {
    box-shadow: 0 0 0 3px #F64A91 inset !important
}

.popover {
    border: 4px solid #E7F1FD;
    box-shadow: 0 4px 10px 0 rgba(108, 158, 195, .09) !important
}

.popover .popover-header {
    color: #212529;
    background-color: #E7F1FD;
    border-bottom: 1px solid #E7F1FD
}

.popover .popover-body {
    color: #212529
}

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-top .arrow::after {
    border-top-color: #E7F1FD
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-bottom .arrow::after {
    border-bottom-color: #E7F1FD
}

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-left .arrow::after {
    border-left-color: #E7F1FD
}

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-right .arrow::after {
    border-right-color: #E7F1FD
}

.preloader.pls-primary .plc-path {
    stroke: #399AF2
}

.qt-loader.qt-loader-primary:before {
    border: 2px solid #399AF2;
    border-left-color: transparent;
    border-bottom-color: transparent
}

.qt-loader:hover.qt-loader-primary:before {
    border: 2px solid #fff;
    border-left-color: transparent;
    border-bottom-color: transparent
}

.qt-loader.qt-loader-accent:before {
    border: 2px solid #F64A91;
    border-left-color: transparent;
    border-bottom-color: transparent
}

.qt-loader:hover.qt-loader-accent:before {
    border: 2px solid #fff;
    border-left-color: transparent;
    border-bottom-color: transparent
}

.swal2-popup .swal2-styled {
    box-shadow: none;
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: .65rem 1rem;
    font-size: .8125rem;
    line-height: 1.25;
    border-radius: .25rem;
    transition: all .15s ease;
    cursor: pointer
}

.swal2-popup .swal2-styled.swal2-confirm {
    border: 1px solid #399AF2 !important;
    background-color: #399AF2 !important;
    color: rgba(255, 255, 255, .85) !important
}

.swal2-popup .swal2-styled.swal2-confirm:hover {
    background-color: #51a6f4 !important;
    border: 1px solid #51a6f4 !important;
    color: #fff !important
}

.swal2-popup .swal2-styled.swal2-confirm:active {
    background-color: #218ef0 !important;
    border: 1px solid #218ef0 !important;
    color: #fff !important;
    box-shadow: none !important
}

.swal2-popup .swal2-styled.swal2-confirm:focus {
    background-color: #399AF2 !important;
    border: 1px solid #399AF2 !important;
    color: #fff !important;
    box-shadow: none !important
}

.swal2-popup .swal2-styled:focus {
    box-shadow: none !important
}

.swal2-popup .swal2-styled i {
    color: rgba(255, 255, 255, .85)
}

::placeholder {
    color: rgba(120, 141, 180, .7) !important;
    opacity: 1
}

:-ms-input-placeholder {
    color: rgba(120, 141, 180, .7) !important
}

::-ms-input-placeholder {
    color: rgba(120, 141, 180, .7) !important
}

.custom-control-input:disabled~.custom-control-label,
.custom-file-label,
.custom-file-label:after,
.form-control,
.form-control:focus {
    color: #323a48
}

.form-control {
    border: 1px solid rgba(120, 141, 180, .3)
}

.form-control:focus {
    border: 1px solid #399AF2
}

.form-control-plaintext {
    color: #617182
}

option:active,
option:checked {
    color: #fff;
    background: linear-gradient(0deg, #E7F1FD 0, #E7F1FD 100%) #E7F1FD
}

select[multiple]:focus option:checked {
    background: linear-gradient(0deg, #399AF2 0, #399AF2 100%) #399AF2
}

.input-group-text {
    background-color: #f0f6ff;
    border: 1px solid #dfe7f3;
    color: #96a9c6
}

.custom-control-label::before {
    background-color: #c7d9ee;
    color: #fff
}

.custom-checkbox.checkbox-primary .custom-control-input:checked~.custom-control-label::before,
.custom-checkbox.checkbox-primary .custom-control-input:indeterminate~.custom-control-label::before,
.custom-radio.radio-primary .custom-control-input:checked~.custom-control-label::before {
    background-color: #399AF2
}

.custom-checkbox.checkbox-accent .custom-control-input:checked~.custom-control-label::before,
.custom-checkbox.checkbox-accent .custom-control-input:indeterminate~.custom-control-label::before,
.custom-radio.radio-accent .custom-control-input:checked~.custom-control-label::before {
    background-color: #F64A91
}

.control.control-outline input:checked~.control__indicator {
    background-color: 0 0
}

.control.control-outline.control--checkbox .control__indicator:after {
    top: 3px;
    left: 7px;
    width: 5px;
    height: 9px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-width: 0 2px 2px 0
}

.control.control-outline.control--radio .control__indicator:after {
    top: 6px;
    left: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff
}

.control.control-outline.control-primary input:checked~.control__indicator {
    border: 1px solid #399AF2
}

.control.control-outline.control-primary.control--checkbox .control__indicator:after {
    border: solid #399AF2
}

.control.control-outline.control-primary.control--radio .control__indicator:after {
    background-color: #399AF2
}

.control.control-outline.control-accent input:checked~.control__indicator {
    border: 1px solid #F64A91
}

.control.control-outline.control-accent.control--checkbox .control__indicator:after {
    border: solid #F64A91
}

.control.control-outline.control-accent.control--radio .control__indicator:after {
    background-color: #F64A91
}

.control.control-outline.control-accent.control--checkbox .control__indicator:after,
.control.control-outline.control-primary.control--checkbox .control__indicator:after {
    border-width: 0 2px 2px 0
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    color: #fff;
    background-color: #399AF2
}

.select2-container--default.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--open .select2-selection--multiple,
.select2-container--default.select2-container--open .select2-selection--single {
    border-color: #399AF2
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #399AF2;
    outline: 0
}

.custom-file-input:focus~.custom-file-label:after,
.custom-file-label,
.custom-file-label:after,
.form-control {
    border-color: #dfe7f3
}

.custom-file-input:focus~.custom-file-label {
    border-color: #dfe7f3;
    box-shadow: none
}

.custom-file-label::after {
    background-color: #f0f6ff;
    border-left: 1px solid #dfe7f3;
    color: #8da6cb
}

.custom-file-label:active::after,
.custom-file-label:focus::after,
.custom-file-label:hover::after {
    color: #399AF2
}

.custom-file-input:lang(en)~.custom-file-label.btn-icon::after,
.custom-file-label.btn-icon::after {
    font-family: Material-Design-Iconic-Font;
    content: '\f22a'
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f0f6ff
}

.custom-range::-webkit-slider-thumb {
    background-color: #399AF2
}

.custom-range::-webkit-slider-thumb:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #81bff7
}

.custom-range::-webkit-slider-runnable-track {
    background-color: #e8eff9
}

.custom-range::-moz-range-thumb {
    background-color: #399AF2
}

.custom-range::-moz-range-thumb:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-range::-moz-range-thumb:active {
    background-color: #81bff7
}

.custom-range::-moz-range-track {
    background-color: #dee2e6
}

.custom-range::-ms-thumb {
    background-color: #399AF2
}

.custom-range::-ms-thumb:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-range::-ms-thumb:active {
    background-color: #81bff7
}

.custom-range::-ms-fill-lower {
    background-color: #dee2e6
}

.custom-range::-ms-fill-upper {
    background-color: #dee2e6
}

.slider.noUi-connect {
    background-color: #F64A91
}

.slider.slider-primary .noUi-connect,
.slider.slider-primary.noUi-connect,
.tgl-flat.tgl-flat-primary:checked+.tgl-btn:after,
.tgl-primary:checked+.tgl-btn {
    background-color: #399AF2
}

.slider .noUi-handle {
    border-color: #F64A91
}

.slider.slider-primary .noUi-handle {
    border-color: #399AF2
}

.tgl-flat.tgl-flat-secondary:checked+.tgl-btn:after,
.tgl-secondary:checked+.tgl-btn {
    background-color: #788db4
}

.tgl-accent:checked+.tgl-btn,
.tgl-flat.tgl-flat-accent:checked+.tgl-btn:after {
    background-color: #F64A91
}

.datepicker table tbody tr>td.day.today,
.datepicker table tr td span.focused,
.datepicker table tr td span:hover,
.datetimepicker table tbody tr>td.day.today,
.form-wizard .steps ul li.active span,
.form-wizard .steps ul li:hover span {
    color: #fff;
    background: #399AF2
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active[disabled],
.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today.disabled:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today[disabled],
.datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active.disabled.active,
.datetimepicker table tr td span.active.disabled:active,
.datetimepicker table tr td span.active.disabled:hover.active,
.datetimepicker table tr td span.active.disabled:hover:active,
.datetimepicker table tr td span.active:active,
.datetimepicker table tr td span.active:hover.active,
.datetimepicker table tr td span.active:hover:active,
.datetimepicker table tr td.active.active,
.datetimepicker table tr td.active.disabled.active,
.datetimepicker table tr td.active.disabled:active,
.datetimepicker table tr td.active.disabled:hover.active,
.datetimepicker table tr td.active.disabled:hover:active,
.datetimepicker table tr td.active:active,
.datetimepicker table tr td.active:hover.active,
.datetimepicker table tr td.active:hover:active {
    background: #F64A91;
    color: #fff
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background: #399AF2
}

.daterangepicker .input-mini.active {
    border: 1px solid #399AF2
}

.daterangepicker td.in-range {
    background-color: #399AF2;
    border-color: transparent;
    color: #fff
}

.ranges li.active,
.ranges li:hover {
    background-color: #399AF2;
    border: 1px solid #399AF2;
    color: #fff
}

.ranges li {
    color: #617182
}

.wizard>.steps a {
    background: #94AAC5;
    color: rgba(255, 255, 255, .6) !important
}

.wizard>.steps a .number {
    background: #7390b3
}

.wizard>.steps .current a {
    background: #399AF2;
    color: #fff !important
}

.wizard>.steps .current a .number {
    background: #0f81e9;
    color: #fff
}

.wizard>.steps .current a:hover {
    color: #fff
}

.wizard>.steps .done a {
    background: #399AF2;
    color: #fff
}

.wizard>.steps .done a .number {
    background: #0f81e9
}

.wizard>.steps .error a {
    background: #ff5c75;
    color: #fff
}

.wizard>.steps .error a .number {
    background: #ff294a;
    color: #fff
}

.wizard>.actions a[href="#previous"] {
    background-color: #fff;
    border: 1px solid #d8e3ef;
    color: #788db4
}

.wizard>.actions a[href="#previous"]:hover {
    background-color: #839ebe !important;
    border: 1px solid #839ebe !important;
    box-shadow: 0 5px 10px rgba(141, 166, 195, .4) !important;
    color: #fff !important
}

.wizard>.actions .disabled a,
.wizard>.actions .disabled a:active,
.wizard>.actions .disabled a:hover {
    color: rgba(141, 166, 195, .6) !important;
    background: 0 0 !important;
    border-color: rgba(216, 227, 239, .7) !important;
    box-shadow: none !important
}

.wizard>.actions a[href="#next"] {
    background-color: #399AF2;
    border: 1px solid #399AF2;
    color: rgba(255, 255, 255, .9) !important
}

.wizard>.actions a[href="#next"]:hover {
    color: #fff !important
}

.wizard>.actions a[href="#finish"] {
    background-color: #399AF2;
    border: 1px solid #399AF2;
    color: rgba(255, 255, 255, .9)
}

.card.card-accent .card-footer,
.card.card-primary .card-footer {
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.wizard>.actions a[href="#finish"]:hover {
    color: #fff !important
}

.actions li>a:hover i:after {
    content: '';
    background-color: rgba(234, 243, 253, .7)
}

.actions>a>i,
.actions>li>a>i {
    color: #788db4
}

.actions.open>a>i,
.actions>a:hover>i,
.actions>li.open>a>i,
.actions>li>a:hover>i {
    color: #399AF2
}

.actions.actions-alt>li.open>a>i,
.actions.actions-alt>li>a>i,
.actions.actions-alt>li>a>i:hover {
    color: #fff
}

.action-header {
    border-bottom: 1px solid #F7F7F7
}

.card.card-accent .card-header,
.card.card-primary .card-header {
    border-bottom: 1px solid rgba(0, 0, 0, .1)
}

.dropdown .dropdown-menu .actions li a {
    color: #718599
}

.chartist-accent .ct-series-a .ct-bar,
.chartist-accent .ct-series-a .ct-line,
.chartist-accent .ct-series-a .ct-point,
.chartist-accent .ct-series-a .ct-slice-donut {
    stroke: #F64A91
}

.chartist-primary .ct-series-a .ct-bar,
.chartist-primary .ct-series-a .ct-line,
.chartist-primary .ct-series-a .ct-point,
.chartist-primary .ct-series-a .ct-slice-donut {
    stroke: #399AF2
}

.chartist-accent .ct-series-a .ct-area,
.chartist-accent .ct-series-a .ct-slice-pie {
    fill: #F64A91
}

.fc-unthemed .fc-toolbar .fc-button.fc-state-active,
.fc-unthemed .fc-toolbar .fc-button:active,
.fc-unthemed .fc-toolbar .fc-button:focus {
    color: #fff;
    background-color: #399AF2
}

.d-fc-event-primary {
    background-color: #399AF2
}

.fc-h-event.brand-primary .fc-content .fc-time,
.fc-h-event.brand-primary .fc-content .fc-title {
    color: #fff
}

.app-content .list-group .list-group-item:hover .list-group-item-heading {
    color: #399AF2 !important
}

.app-content .list-group .list-group-item.active .list-group-item-heading,
.app-content .list-group .list-group-item.active .list-group-item-text,
.app-content .list-group .list-group-item.mark-read .list-group-item-heading,
.app-content .list-group .list-group-item.mark-read .list-group-item-text {
    color: #91a6c3
}

.app-content .list-group .list-group-item.mark-read {
    background-color: rgba(231, 241, 253, .2)
}

.app-content .list-group .list-group-item.active {
    border-left: solid 3px #399AF2;
    background-color: rgba(231, 241, 253, .2)
}

.card.card-primary {
    background: #399AF2
}

.card.card-primary .card-header .actions div>a>i,
.card.card-primary .card-header .actions li>a>i {
    color: rgba(255, 255, 255, .5)
}

.card.card-primary .card-body p,
.card.card-primary .card-body span,
.card.card-primary .card-footer p,
.card.card-primary .card-footer span,
.card.card-primary .card-header,
.card.card-primary .card-header .actions div>a>i:hover,
.card.card-primary .card-header .actions li>a>i:hover,
.card.card-primary .card-title {
    color: #fff
}

.card.card-accent {
    background: #F64A91
}

.card.card-accent .card-header .actions div>a>i,
.card.card-accent .card-header .actions li>a>i {
    color: rgba(255, 255, 255, .5)
}

.card.card-accent .card-body p,
.card.card-accent .card-body span,
.card.card-accent .card-footer p,
.card.card-accent .card-footer span,
.card.card-accent .card-header,
.card.card-accent .card-header .actions div>a>i:hover,
.card.card-accent .card-header .actions li>a>i:hover,
.card.card-accent .card-title,
.card.card-border-primary .card-header .card-title {
    color: #fff
}

.card.card-border-primary {
    border: 1px solid #399AF2
}

.card.card-border-primary .card-header {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    background: #399AF2;
    color: #fff
}

.card.card-border-primary .card-header .actions div>a>i,
.card.card-border-primary .card-header .actions li>a>i {
    color: rgba(255, 255, 255, .5)
}

.card.card-border-accent .card-header .card-title,
.card.card-border-primary .card-header .actions div>a>i:hover,
.card.card-border-primary .card-header .actions li>a>i:hover {
    color: #fff
}

.card.card-border-accent {
    border: 1px solid #F64A91
}

.card.card-border-accent .card-header {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    background: #F64A91;
    color: #fff
}

.card.card-pills.card-accent .nav-pills.nav-pills-accent .nav-item .nav-link.active,
.card.card-pills.card-accent .nav-pills.nav-pills-accent .show>.nav-link,
.card.card-pills.card-primary .nav-pills.nav-pills-primary .nav-item .nav-link.active,
.card.card-pills.card-primary .nav-pills.nav-pills-primary .show>.nav-link {
    background-color: #fff
}

.card.card-border-accent .card-header .actions div>a>i,
.card.card-border-accent .card-header .actions li>a>i {
    color: rgba(255, 255, 255, .5)
}

.card.card-border-accent .card-header .actions div>a>i:hover,
.card.card-border-accent .card-header .actions li>a>i:hover {
    color: #fff
}

.card.card-pills.card-primary .nav-pills.nav-pills-primary .nav-item .nav-link {
    color: rgba(255, 255, 255, .5)
}

.card.card-pills.card-primary .nav-pills.nav-pills-primary .nav-item .nav-link:hover {
    color: #fff
}

.card.card-pills.card-primary .nav-pills.nav-pills-primary .nav-item .nav-link.active {
    color: #399AF2
}

.card.card-pills.card-accent .nav-pills.nav-pills-accent .nav-item .nav-link {
    color: rgba(255, 255, 255, .5)
}

.card.card-pills.card-accent .nav-pills.nav-pills-accent .nav-item .nav-link:hover {
    color: #fff
}

.card.card-pills.card-accent .nav-pills.nav-pills-accent .nav-item .nav-link.active {
    color: #F64A91
}

.card.card-tabs.card-accent .card-header,
.card.card-tabs.card-primary .card-header {
    border: none
}

.card.card-tabs.card-accent .nav-tabs,
.card.card-tabs.card-primary .nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, .1)
}

.card.card-tabs.card-accent .nav-tabs .nav-item .nav-link,
.card.card-tabs.card-primary .nav-tabs .nav-item .nav-link {
    color: rgba(255, 255, 255, .6)
}

.card.card-tabs.card-accent .nav-tabs .nav-item .nav-link:hover,
.card.card-tabs.card-primary .nav-tabs .nav-item .nav-link:hover {
    color: #fff
}

.card.card-tabs.card-accent .nav-tabs .nav-item.show .nav-link,
.card.card-tabs.card-accent .nav-tabs .nav-link.active,
.card.card-tabs.card-primary .nav-tabs .nav-item.show .nav-link,
.card.card-tabs.card-primary .nav-tabs .nav-link.active {
    background-color: rgba(255, 255, 255, .1);
    color: #fff
}

.card.card-menu .metismenu li a {
    color: #617182
}

.card.card-menu .metismenu li a:hover {
    color: #399AF2
}