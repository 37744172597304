@import "bootstrap/dist/css/bootstrap.min.css";
@import "./assets/fonts/BeyondTheMountains/BeyondTheMountains.css";
@import "./assets/vendor/metismenu/metisMenu.css";
@import "./assets/css/icons/line-awesome/css/line-awesome.min.css";
@import "./assets/css/icons/material-design-iconic-font/css/material-design-iconic-font.min.css";
@import "./assets/css/icons/dripicons/dripicons.css";
@import "./assets/css/icons/fontawesome/css/all.min.css";
@import "./assets/css/common/main.bundle.css";
@import "./assets/css/layouts/vertical/core/main.css";
@import "./assets/css/layouts/vertical/menu-type/default.css";
@import "./assets/css/layouts/vertical/themes/theme-k.css";
@import "./assets/vendor/bootstrap-fileinput/bootstrap-fileinput.css";
@import "./assets/css/style.css";



.loading-1 {
    $size: 0.5em;
    $circumference: 3.14 * 2 * $size;

    .circle {
        stroke: #ffff;
        fill: none;
        stroke-width: 3px;
        stroke-dashoffset: -$circumference;
        stroke-dasharray: $circumference;
        animation: loading1 1.4s cubic-bezier(0.42, 0, 0.29, 1) infinite;
        transform: rotate(-90deg);
        transform-origin: 50%;
        position: relative;
    }

    @keyframes loading1 {
        0% {
            stroke-dashoffset: $circumference;
        }

        50% {
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dashoffset: -$circumference;
        }
    }
}

.loading-2 {
    $duration: 0.7s;
    $size: 2em;

    .spinner * {
        box-sizing: border-box;
    }

    .spinner {
        display: inline-block;
        animation-name: anim-spinner;
        animation-duration: $duration;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        .circle {
            width: $size;
            height: ($size/2);
            overflow: hidden;
        }

        .circle-inner {
            transform: rotate(45deg);
            border-radius: 50%;
            border: ($size/8) solid black;
            border-right: ($size/8) solid transparent;
            border-bottom: ($size/8) solid transparent;
            width: 100%;
            height: 200%;
            animation-name: anim-circle-1;
            animation-duration: $duration;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: cubic-bezier(0.25, 0.1, 0.5, 1);
        }

        .circle-2 {
            transform: rotate(180deg);

            & .circle-inner {
                animation-name: anim-circle-2;
            }
        }

        @keyframes anim-circle-1 {
            from {
                transform: rotate(60deg);
            }

            to {
                transform: rotate(205deg);
            }
        }

        @keyframes anim-circle-2 {
            from {
                transform: rotate(30deg);
            }

            to {
                transform: rotate(-115deg);
            }
        }

        @keyframes anim-spinner {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }
}

.loading-3 {
    .loading {
        background: transparent;
        width: 2em;
        height: 2em;
        position: relative;
        margin: 0 auto;
    }

    .loading:after {
        display: block;
        content: "";
        border-radius: 50%;
        height: 2px;
        width: 2px;
        position: absolute;
        margin: auto;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        box-shadow: 10px 0 0 2px #333, 7px 7px 0 2px #999, 0 10px 0 2px #999, -7px 7px 0 2px #999, -10px 0 0 2px #999,
            -7px -7px 0 2px #999, 0 -10px 0 2px #333, 7px -7px 0 2px #111;
        -webkit-animation: rotate 0.7s steps(8) infinite;
        -o-animation: rotate 0.7s steps(8) infinite;
        animation: rotate 0.7s steps(8) infinite;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}

.loading-4 .loading {
    $size: 0.5em;
    width: $size;
    height: $size;
    background: transparent;
    margin: 2em;
    border-radius: 50%;
    animation: circ-anim 0.9s linear infinite;

    box-shadow: ($size * 1.2) 0 0 0 #333, (-$size/1.5) (-$size) 0 0 #333, (-$size/1.5) ($size) 0 0 #333;
}

.loading-5 {
    $size: 0.5em;
    $circumference: 3.14 * 2 * $size;
    $offset: 0.85;
    $circumference: $circumference * $offset;

    & .svg-wrapper {
        background: white;
        width: $size * 3;
        height: $size * 3;
        margin: 0 Auto;
        padding: 5px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
    }

    .circle {
        stroke: #000066;
        fill: none;
        stroke-width: 3px;
        stroke-dashoffset: -$circumference;
        stroke-dasharray: $circumference;
        animation: loading5 1.4s linear infinite, color1 10s linear infinite;
        transform: rotate(-90deg);
        transform-origin: 50%;
        position: relative;
    }

    @keyframes loading5 {
        0% {
            transform: rotate(0);
            stroke-dashoffset: $circumference;
        }

        50% {
            stroke-dashoffset: 0;
        }

        100% {
            transform: rotate(360deg);
            stroke-dashoffset: -$circumference;
        }
    }

    @keyframes color1 {
        0% {
            stroke: #d8334a;
        }

        10% {
            stroke: #fc6e51;
        }

        20% {
            stroke: #ffce54;
        }

        30% {
            stroke: #a0d468;
        }

        40% {
            stroke: #a0cecb;
        }

        50% {
            stroke: #4fc1e9;
        }

        60% {
            stroke: #8067b7;
        }

        70% {
            stroke: #baa286;
        }

        80% {
            stroke: #ccd1d9;
        }

        90% {
            stroke: #2ecc71;
        }

        100% {
            stroke: #d8334a;
        }
    }
}

.loading-6 {
    svg {
        width: 3.75em;
        transform-origin: center;
        animation: rotate 2s linear infinite;
    }

    circle {
        fill: none;
        stroke: #fc2f70;
        stroke-width: 2;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }

        50% {
            stroke-dasharray: 90, 200;
            stroke-dashoffset: -35px;
        }

        100% {
            stroke-dashoffset: -125px;
        }
    }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    font-family: 'Roboto', sans-serif;
}



.jss44 {
    // color: #718eb1;
}

.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.MuiListItemIcon-root {
    // color: #fff;
    display: inline-flex;
    min-width: 30px;
    flex-shrink: 0;
    vertical-align: bottom;
    margin-left: -3px;
}

.MuiListItemIcon-root-reg {
    // color: #fff;
    display: inline-flex;
    min-width: 50px;
    flex-shrink: 0;
    vertical-align: bottom;
    margin-left: -3px;
}

.MuiListItemIcon-root-tbl {
    // color: #fff;
    display: inline-flex;
    min-width: 30px;
    flex-shrink: 0;
    vertical-align: middle;
    margin-left: -3px;
}

.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li>a i {
    vertical-align: baseline !important;
}

.divider-label {
    padding: 0px 22px 0px 22px;
    color: #465f7e;
    font-size: 17px;
}

hr {
    margin-top: 0rem;
    margin-bottom: 0rem;
}

thead>tr {
    line-height: 32px !important;

}

tr {
    line-height: 30px;
}

.breadcrumb {
    background-color: transparent !important;
}

.MuiChip-icon {
    margin-right: -18px !important;
}


.intext {
    margin: 15px 0;

    figure {
        margin: 0 15px 15px 0;

        figcaption {
            font-size: small;
            color: white;
            background: black;
            padding: 5px;
        }
    }

    h2 {
        margin-top: 0;
    }
}


.css-19kzrtu {
    padding: 0px !important;
}



#container {
    margin: 1rem;
}

.items {
    margin-bottom: 1rem;
}


.gift-img {
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
}

.gift-image-before {
    position: relative;
}

.gift-image-after {
    position: relative;

}

.gift-image-expired {
    position: relative;
    opacity: .3;
}


.dec {
    // color:#68439a !important;
    font-size: 12px;

    &-title {
        font-weight: 600;
        margin: 12px 0;
    }

    &-content {
        ol {
            padding: 0 12px;

            li {
                padding: 6px 0;
            }
        }

        .roman {
            list-style-type: lower-roman;
            margin-left: 6px;
        }
    }
}

.attch-container {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.attch-container img {
    width: 200px;
    height: 120px !important;
    height: auto;
    border: solid 1px #ddd;
    border-radius: 6px;
}

.attch-container .btns {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #f0f0f0;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    i{
        color: rgb(0 0 0 / 70%);
    }
}

// .MuiChip-colorDefault{
//     color:white !important;
//     background-color: gray !important;
// }
