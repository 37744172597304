/* Generated by Font Squirrel (https://www.fontsquirrel.com) on September 5, 2016 */
@font-face {
    font-family: 'beyond_the_mountainsregular';
    src: url('beyond_the_mountains-webfont.eot');
    src: url('beyond_the_mountains-webfont.eot?#iefix') format('embedded-opentype'),
         url('beyond_the_mountains-webfont.woff2') format('woff2'),
         url('beyond_the_mountains-webfont.woff') format('woff'),
         url('beyond_the_mountains-webfont.ttf') format('truetype'),
         url('beyond_the_mountains-webfont.svg#beyond_the_mountainsregular') format('svg');
    font-weight: normal;
    font-style: normal;

}