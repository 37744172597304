@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: "blanche_de_la_fontaineregular";
  src:
    url("../fonts/la-fontaine/blanche_de_la_fontaine-webfont.woff2")
      format("woff2"),
    url("../fonts/la-fontaine/blanche_de_la_fontaine-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

/* #39b54a */

/* ********************************************************************************************
    Reset
*********************************************************************************************** */
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f6f8fa;
}

.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu,
.site-logo li .brand-text,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.nav.metismenu,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

.h1,
.h2,
.h3,
body,
h1,
h2,
h3,
html {
  font-weight: 400;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2b2b2b;
}

.text-default {
  color: #9fa4b3 !important;
}

.text-accent-custom {
  color: #68439a !important;
}

.o-contain {
  object-fit: contain;
}

.o-cover {
  object-fit: cover;
}

.w-100p,
.w-100p ~ .select2-container {
  width: 100% !important;
}

a,
.pointer {
  cursor: pointer !important;
}

.actions > li {
  vertical-align: top;
}

.tab-pane .actions.top-right {
  top: 12px;
}

strong {
  font-weight: bold;
}

.overflow-auto {
  overflow: auto;
}

.tgl + .tgl-btn {
  width: 2.55em;
  height: 1.35em;
}

.border {
  border: 1px solid rgba(210, 221, 234, 0.5) !important;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

button.disabled {
  pointer-events: none;
}

.w-75p {
  width: 75% !important;
}

.w-170 {
  width: 170px;
}

.font-size-13 {
  font-size: 13px;
}

.overflow-y-auto {
  overflow-y: auto;
}

/* ********************************************************************************************
    Page Header & Page Content
*********************************************************************************************** */

.page-header h1 {
  font-weight: 600;
}

.page-content {
  padding-top: 32px;
}

.profile-item .btn.btn-primary:hover,
.profile-item .btn.btn-primary {
  background: transparent !important;
  border: 0 !important;
}

.profile-item .btn.btn-primary::after {
  display: none;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  line-height: 1.3;
}

.card.card-tabs .card-header .nav.nav-tabs li:first-child a.active {
  border-radius: 0.25rem 0 0 0;
}

.view-badge {
  vertical-align: 19%;
  margin-left: 10px;
  display: inline-block;
  padding: 4px 10px 4px;
}

.badge-orange {
  background-color: #f39c12 !important;
  color: rgba(255, 255, 255, 0.85);
}

.badge-green {
  background-color: #70d144 !important;
  color: rgba(255, 255, 255, 0.85);
}

.badge-dark {
  background-color: #343a40 !important;
}

.badge-pill {
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
}

.main-container {
  position: relative;
  height: 100vh;
  width: 100%;
}

.tab-content .tab-pane .d-flex .btn {
  height: 35px;
  line-height: 18px;
}

.card-notification .card-container {
  max-height: 350px;
  overflow-y: auto;
}

.card-notification .actions .dropdown-menu {
  top: 25% !important;
  min-width: 200px !important;
  min-height: auto !important;
  width: 200px !important;
}

.card-notification .actions .btn-group .dropdown-toggle.btn-primary i {
  font-size: 22px !important;
}

.equal-height {
  height: 100%;
}

.profile-item-container {
  /* placed in row */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.profile-item-name {
  /* placed in column */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

@media only screen and (max-width: 500px) {
  .profile-item-name {
    margin-left: 0px;
    font-size: 10px;
  }
  .profile-avatar {
    width: 25px !important;
    height: 25px !important;
  }
}
/* ********************************************************************************************
    Button
*********************************************************************************************** */

button,
.btn {
  outline: none !important;
}

.btn-primary,
a.btn-primary,
a:visited.btn-primary a:link.btn-primary {
  background-color: #68439a;
  border: 1px solid #68439a;
  color: rgba(255, 255, 255, 0.9);
}

.btn-primary:hover,
a.btn-primary:hover,
a:visited.btn-primary a:link.btn-primary:hover {
  background-color: #68439a !important;
  border: 1px solid #68439a !important;
  box-shadow: 0 3px 6px rgba(104, 67, 154, 0.3) !important;
  color: #fff;
}

.btn-primary:focus,
a.btn-primary:focus,
a:visited.btn-primary a:link.btn-primary:focus {
  background-color: #68439a;
  border: 1px solid #68439a;
  color: #fff !important;
  box-shadow: none !important;
}

.btn-primary:active,
a.btn-primary:active,
a:visited.btn-primary a:link.btn-primary:active {
  background-color: #68439a !important;
  border: 1px solid #68439a !important;
  color: #fff !important;
  box-shadow: none !important;
}

.btn-info,
a:link.btn-info {
  color: rgba(255, 255, 255, 0.85) !important;
}

.btn-info.btn-outline,
a:link.btn-info.btn-outline {
  color: #399af2 !important;
}

.btn.btn-sm,
a:link.btn.btn-sm {
  padding: 0.5rem 0.75rem;
  height: auto;
}

.btn-icon-only {
  width: 30px !important;
  height: 25px !important;
  text-align: center !important;
  padding: 0px !important;
}

.btn-icon-only i {
  margin: 0 !important;
  padding: 0;
}

.btn-secondary:hover i,
.btn-secondary:focus i {
  color: #fff;
}

.dropdown-toggle.btn-secondary.btn-primary {
  background-color: #fff;
  border: 1px solid #d8e3ef;
  color: #788db4 !important;
}

.dropdown-toggle.btn-secondary.btn-primary:hover,
a:link.dropdown-toggle.btn-secondary.btn-primary:hover {
  background-color: #6e85af !important;
  border: 1px solid #6e85af !important;
  box-shadow: 0 5px 10px rgba(120, 141, 180, 0.4) !important;
  color: #fff !important;
}

.dropdown-toggle.btn-secondary.btn-primary:not(:disabled):not(.disabled).active,
.dropdown-toggle.btn-secondary.btn-primary:not(:disabled):not(.disabled):active,
.show > .dropdown-toggle.btn-secondary.btn-primary {
  background-color: #fff !important;
  border: 1px solid #d8e3ef !important;
  color: #788db4 !important;
}

.dropdown-toggle.btn-secondary.btn-primary:focus i {
  color: #788db4;
}

.dropdown-toggle.btn-secondary.btn-primary::after {
  display: none;
}

.dropdown-toggle.btn-secondary.btn-primary i {
  margin-left: 0px;
}

.dropdown .fa-sliders {
  margin-top: -5px;
}

.btn-primary2 {
  background-color: #6e85af !important;
  border-color: #6e85af;
  color: #fff;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.button-with-textbox,
.page-header .btn,
.page-header a:link.btn,
.removebtn {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 35px;
}

.btn-secondary.add-money-btn:focus {
  background-color: #fff;
  border: 1px solid #d8e3ef;
  color: #788db4 !important;
}

.btn-secondary.add-money-btn:hover,
.btn-secondary.add-money-btn:hover i {
  color: #fff !important;
}

.btn-secondary.add-money-btn:focus i {
  color: #788db4 !important;
}

.btn-secondary.add-money-btn:not(:disabled):not(.disabled).active:focus,
.btn-secondary.add-money-btn:not(:disabled):not(.disabled):active:focus {
  color: #fff !important;
}

.btn-secondary.add-money-btn:not(:disabled):not(.disabled):active:focus i {
  color: #fff !important;
}

.btn-secondary.add-money-btn:active,
a:link.btn-secondary.add-money-btn:active,
.btn-secondary.add-money-btn:active i,
a:link.btn-secondary.add-money-btn:active i {
  color: #fff !important;
}

.term-and-conditions-btn {
  min-width: 260px !important;
}

@media screen and (max-width: 400px) {
  .term-and-conditions-btn {
    font-size: 0.7rem !important;
    padding: 0%;
  }
}

/* ********************************************************************************************
    Navbar & Sidebar
*********************************************************************************************** */

.site-logo li .logo {
  top: 15px;
  left: 20px;
}

.site-logo li .brand-text {
  padding: 20px 0 0 32px;
}

.logo-custom {
  margin-top: 13px;
  margin-left: 25px;
}

.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
  font-size: 0.9rem;
}

body .top-toolbar.navbar-desktop {
  background: #2b343e;
}

body .aside-toolbar,
body .top-toolbar.navbar-mobile.navbar-tablet {
  background-color: #2b343e;
}

body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a,
body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a i {
  color: rgba(255, 255, 255, 0.95);
}

body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  li
  a:hover,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  li
  a:hover
  i {
  color: #68439a !important;
}

.mCSB_inside > .mCSB_container {
  margin-right: 15px;
}

.top-toolbar .dropdown .dropdown-menu {
  top: 100%;
}

.top-toolbar .card.card-notification .card-header {
  min-height: 50px;
  height: 50px;
}

.top-toolbar .card.card-notification .card-header .actions.top-right {
  top: 10px;
  right: 10px;
}

body .top-toolbar {
  position: fixed;
  right: 0;
  left: 0;
}

body .sidebar-left {
  position: fixed !important;
  height: 100% !important;
}

.sidebar.sidebar-left .sidebar-content {
  top: 65px;
  z-index: 9999;
}

body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .metismenu
  .open
  > a:focus,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .metismenu
  .open
  > a:hover,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a
  > i,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown.active
  > ul
  > li.nav-dropdown.active
  > ul
  > li.active
  > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown
  > ul
  > li.nav-dropdown
  > ul
  > li
  > a:hover,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li
  > a:active,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li
  > a:hover {
  color: #68439a;
}

body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a:focus,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a:hover {
  border-left: solid 3px #68439a;
}

body .top-toolbar {
  right: 0;
  left: 0;
  position: fixed;
}

.sidebar-left {
  position: fixed !important;
  height: 100% !important;
}

body .sidebar-left,
body .sidebar-left .sidebar-content {
  background-color: #fff;
}

body .sidebar-left ~ .content-wrapper .content {
  padding-top: 65px;
  margin-left: 255px;
}

body .content {
  padding-top: 65px;
}

.mCSB_inside > .mCSB_container {
  margin-right: 15px;
}

.sidebar.sidebar-left .sidebar-content .main-menu {
  max-height: calc(100vh - 65px);
  padding-bottom: 10px;
}

.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu {
  overflow-y: hidden;
}

/* ********************************************************************************************
    Table
*********************************************************************************************** */

.table-hover tbody tr:hover {
  background-color: rgba(231, 241, 253, 0.2);
}

.table tbody th {
  font-weight: 500;
}

.table td,
.table th {
  padding: 0.55rem 0.75rem;
  border-top: none;
}

.table td,
.table th,
.table thead th {
  vertical-align: middle;
}

.table-field-status .dropdown .dropdown-menu .dropdown-item i {
  vertical-align: inherit;
  font-size: 11px;
  margin-right: 5px;
}

.table-field-actions,
.table-field-status {
  width: 120px !important;
  text-align: center;
}

.table-field-actions .dropdown-menu,
.table-field-status .dropdown-menu {
  width: 150px;
}

.table-field-actions .dropdown .dropdown-menu .dropdown-item {
  padding: 10px;
}

.table tr td:first-child,
.table tr th:first-child {
  padding-left: 1.25rem;
}

.remove-padding.table td:first-child,
.remove-padding.table th:first-child {
  padding-left: 0 !important;
}

.remove-padding.table td:last-child,
.remove-padding.table th:last-child {
  padding-right: 0 !important;
}

.table-small-padding.table > tbody > tr > th,
.table-small-padding.table > tbody > tr > td {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 13px;
  font-weight: normal;
}

.table > thead > tr > th > a {
  position: relative;
  color: #617182;
}

.table > thead > tr > th > a:before,
.table > thead > tr > th > a:after {
  position: absolute;
  display: block;
  opacity: 0.5;
  bottom: -0.15em;
  margin-right: -25px;
}

.table > thead > tr > th > a:before {
  content: "\2193";
  right: 0em;
}

.table > thead > tr > th > a:after {
  content: "\2191";
  right: 0.5em;
}

.table > thead > tr > th.descending > a:before,
.table > thead > tr > th.ascending > a:after {
  opacity: 1;
}

.table > thead > tr > th button {
  background: transparent;
  border: 0;
  color: #5accca;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.table > thead > tr > th button .caret {
  float: right;
}

.table > thead > tr > th button .caret:before {
  font: normal normal normal 14px/1 LineAwesome;
  display: block;
  font-size: 18px;
  margin-top: 2px;
  width: 100%;
  color: #718eb1;
  float: right;
}

.table > thead > tr > th button.desc .caret:before {
  content: "\f308";
}

.table > thead > tr > th button.asc .caret:before {
  content: "\f307";
}

.table > thead > tr > th button:focus {
  outline: none;
}

.table .sorting span {
  position: relative;
  cursor: pointer;
}

.table .sorting span::before,
.table .sorting span::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  right: -0.75em;
  content: "\2191";
  margin-top: 0px;
}

.table .sorting span::after {
  right: -1.25em;
  content: "\2193";
}

.table tbody + tbody {
  border-top: none;
}

.nowrap,
.table-responsive .table tr th {
  white-space: nowrap;
}

.table-responsive .table tr th svg {
  margin-left: 5px;
}

/* Pagination */
.pagination {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  margin: 0;
  padding: 1rem;
}

.pagination li.active a {
  z-index: 1;
  color: #fff;
  background-color: #1bc500;
  border-color: #1bc500;
  border-radius: 100%;
  box-shadow: none;
  outline: 0;
  width: 30px;
  height: 30px;
  text-align: center;
}

/* .pagination li a,
.pagination li span, */
.pagination .page-item.active .page-link {
  width: 30px;
  height: 30px;
  line-height: 30px !important;
  text-align: center;
  display: block;
}

.pagination .page-item.active .page-link {
  background-color: #68439a;
  border-color: #68439a;
  padding: 0px !important;
}

/* .page-link {
    padding: 0px !important;
} */
.page-item.previous a,
.page-item.next a {
  width: auto !important;
  padding: 0px 10px !important;
}

.pagination a:hover {
  color: #68439a;
}

.pagination li a {
  padding: 0.5rem 0.75rem;
  text-transform: capitalize;
}

.pagination .page-item.active .page-link {
  background-color: #68439a;
  border-color: #68439a;
}

/* ********************************************************************************************
    Dropdown
*********************************************************************************************** */

.dropdown-notifications a {
  position: relative;
}

.dropdown-notifications a i.circle-dot {
  position: absolute !important;
  right: 18px !important;
  top: 8px !important;
  font-size: 10px !important;
  color: #ef606d !important;
}

.dropdown.filters .dropdown-menu,
.dropdown300 {
  width: 300px !important;
}

.dropdown-menu #filter-form .fa-times {
  font-size: 14px;
  margin-top: -3px;
}

.dropdown-menu #filter-form .btn-link {
  padding-left: 0 !important;
  line-height: 20px;
}

.dropdown .dropdown-menu {
  z-index: 1000;
}

.dropdown .dropdown-menu .dropdown-item.active,
.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item.active,
.dropleft .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:focus,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item.active,
.dropright .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:focus,
.dropright .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item.active,
.dropup .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:focus,
.dropup .dropdown-menu .dropdown-item:hover {
  color: #68439a !important;
}

.dropdown .dropdown-menu .dropdown-item.active i,
.dropdown .dropdown-menu .dropdown-item:active i,
.dropdown .dropdown-menu .dropdown-item:focus i,
.dropdown .dropdown-menu .dropdown-item:hover i,
.dropleft .dropdown-menu .dropdown-item.active i,
.dropleft .dropdown-menu .dropdown-item:active i,
.dropleft .dropdown-menu .dropdown-item:focus i,
.dropleft .dropdown-menu .dropdown-item:hover i,
.dropright .dropdown-menu .dropdown-item.active i,
.dropright .dropdown-menu .dropdown-item:active i,
.dropright .dropdown-menu .dropdown-item:focus i,
.dropright .dropdown-menu .dropdown-item:hover i,
.dropup .dropdown-menu .dropdown-item.active i,
.dropup .dropdown-menu .dropdown-item:active i,
.dropup .dropdown-menu .dropdown-item:focus i,
.dropup .dropdown-menu .dropdown-item:hover i {
  color: #68439a;
}

.dropdown-toggle::after {
  position: relative;
  top: 2px;
  left: 2px;
}

.btn-group .dropdown-toggle::after {
  display: none;
}

.btn-group .dropdown-toggle.btn-primary {
  background-color: transparent !important;
  border: none !important;
}

.btn-group .dropdown-toggle.btn-primary:hover {
  box-shadow: none !important;
}

.btn-group .dropdown-toggle.btn-primary i {
  font-size: 24px !important;
}

.btn-group .dropdown-menu {
  top: auto !important;
  animation-name: none;
}

.btn-group .dropdown-menu .dropdown-item {
  color: #839bb3;
}

.btn-group .dropdown-item i {
  margin-right: 0.25rem;
  vertical-align: -1px !important;
}

.btn-group .dropdown-item.active,
.btn-group .dropdown-item:active,
.btn-group .dropdown-item:hover {
  color: #68439a;
  text-decoration: none;
  background-color: rgba(231, 241, 253, 0.2);
}

body .top-toolbar.navbar-desktop .dropdown-menu ul li:hover a {
  color: #68439a;
}

/* ********************************************************************************************
    Tabs
*********************************************************************************************** */
.nav .nav-item .nav-link.active,
.nav .nav-item .nav-link:active,
.nav .nav-item .nav-link:hover,
.nav-tabs.primary-tabs .nav-item .nav-link.active.show,
.nav-tabs.primary-tabs .nav-item .nav-link:hover,
.nav-tabs.primary-tabs .nav-item.show .nav-link {
  color: #68439a;
}

.nav-tabs.primary-tabs .nav-item .nav-link.active {
  border-bottom: solid 1px #68439a;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.navbar-nav li.active a,
.main-menu li.active a {
  color: #68439a !important;
}

/* ********************************************************************************************
    Form
*********************************************************************************************** */

.left-form-content {
  margin-right: 40px;
  margin-left: 20px;
}

.fileinput .thumbnail > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upload-img-label {
  font-size: 11px;
  margin-bottom: 0;
  font-weight: normal;
}

.uploaded-user-image .upload-icon {
  position: absolute;
  bottom: 15px;
  left: 10px;
}

.label {
  font-weight: 600;
}

label em,
.control-label em {
  font-style: normal;
  color: #ff0026;
  font-weight: normal;
  font-family: arial;
}

.form-row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.form-control:focus {
  border: 1px solid #68439a;
}

.form-group .css-1pahdxg-control,
.form-group .css-1s2u09g-control {
  border-color: #dfe7f3;
  min-height: 35px;
  box-shadow: none;
}

.form-group .css-6j8wv5-Input {
  padding-top: 0;
  padding-bottom: 0;
}

.form-group .css-6j8wv5-Input,
.form-group .css-14el2xx-placeholder {
  color: #323a48;
}

/* Change the white to any color */

.bg-register-box input:-webkit-autofill,
.bg-register-box input:-webkit-autofill:hover,
.bg-register-box input:-webkit-autofill:focus,
.bg-register-box input:-webkit-autofill:active {
  background-color: #fff !important;
  transition: background-color 5000s ease-in-out 0s;
}
.dark-theme input:-webkit-autofill,
.dark-theme input:-webkit-autofill:hover,
.dark-theme input:-webkit-autofill:focus,
.dark-theme input:-webkit-autofill:active {
  background-color: #090c10 !important;
  -webkit-text-fill-color: #fff !important;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #fff;
}
.dark-theme .login-box input:-webkit-autofill,
.dark-theme .login-box input:-webkit-autofill:hover,
.dark-theme .login-box input:-webkit-autofill:focus,
.dark-theme .login-box input:-webkit-autofill:active {
  background-color: #090c10 !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: inherit;
}
.dark-theme .login-page .login-box input:-webkit-autofill,
.dark-theme .login-page .login-box input:-webkit-autofill:hover,
.dark-theme .login-page .login-box input:-webkit-autofill:focus,
.dark-theme .login-page .login-box input:-webkit-autofill:active {
  -webkit-text-fill-color: #fff !important;
}

.dark-theme ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.dark-theme .dashboard-gifts-card .badges,
.dark-theme .dashboard-class-card .badges {
  border: 1px solid #30363d !important;
  color: #fff !important;
}
.dark-theme .dashboard-gifts-card .read-more,
.dark-theme .dashboard-class-card .read-more {
  color: #fff !important;
}

/* .dark-theme .login-box input:-webkit-autofill,
.dark-theme .login-box input:-webkit-autofill:hover,
.dark-theme .login-box input:-webkit-autofill:focus,
.dark-theme .login-box input:-webkit-autofill:active{
    background-color: transparent !important;
    -webkit-text-fill-color: #000 !important;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: #fff;
} */

/* .form-group .css-1pahdxg-control,
.form-group .css-tlfecz-indicatorContainer {
    padding: 6px;
} */

.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  height: 35px;
}

/* Select2 */
.input-group
  .select2-container--default:first-of-type
  .select2-selection--single {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  font-family: Arial, Helvetica, sans-serif;
}

.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  min-height: 35px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  /*padding: 0.49rem 1rem;*/
  color: #323a48;
  position: relative;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  font-size: 0.875rem;
  font-weight: normal;
}

.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
  border: 1px solid #736cc7;
  box-shadow: none;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.0625rem + 2px);
}

/* Checkbox & Radio */
.custom-control-label {
  cursor: pointer;
}

.control.control-outline {
  cursor: pointer;
}

.control.control-outline .control__indicator {
  top: 1px;
  border: 1px solid #ccc;
}

.control.control-outline.control-primary input:checked ~ .control__indicator {
  border: 1px solid #68439a;
}

.control.control-outline.control-primary.control--checkbox
  .control__indicator:after {
  border-color: #68439a;
}

.control.control-outline.control-primary.control--radio
  .control__indicator:after {
  background-color: #68439a;
}

.bootstrap-timepicker-widget table td input {
  border-width: 1px;
}

.notifications .control.control-outline .control__indicator,
.accessories .control.control-outline .control__indicator {
  top: 50%;
  transform: translateY(-50%);
}

.btn.btn-lg {
  padding: 25px 25px !important;
  font-weight: 400;
}

/* ********************************************************************************************
    Login
*********************************************************************************************** */

.sign-in-form {
  margin: 10% auto;
}

.login-box,
.register-box {
  width: 100%;
  max-width: 410px;
  box-shadow:
    0 10px 40px 0 rgba(18, 106, 211, 0.07),
    0 2px 9px 0 rgba(18, 106, 211, 0.06);
  border-radius: 10px;
  margin: 3% auto;
}

.login-box.custom-login-box {
  max-width: 610px !important;
}

.login-logo,
.register-logo {
  background: #fff;
  margin-bottom: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-top: 30px;
  text-align: center;
}

.login-box-msg,
.register-box-msg {
  font-weight: normal;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 30px;
  text-align: center;
}

.login-box-body,
.register-box-body {
  color: inherit;
  padding: 30px 25px 20px 25px;
  background: #fff;
}

.login-box-body .form-control {
  background: #fff !important;
  border-color: #6a4397;
  font-size: 15px;
  border-radius: 50px;
  height: 49px;
  text-align: center;
}

.login-box-body .form-control:focus {
  background-color: #fff;
  border-color: #68439a;
}

.login-box-body .btn {
  font-size: 15px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-radius: 50px;
  margin-top: 10px;
}

.login-box-footer {
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 25px 20px;
  font-size: 14px;
}

.login-box-body textarea.form-control {
  border-radius: 7px !important;
  height: inherit !important;
}

.bg-login-image {
  background: url("../img/login-bg-img.png") no-repeat;
  background-size: cover;
  background-position: top center;
  z-index: 1;
  position: relative;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
}

.bg-login-image-low {
  background: url("../img/bg-img-low.jpg") no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  z-index: 1;
}

.bg-image {
  background: url("../img/bg-img.png") no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  z-index: 1;
}

.otp-box input {
  flex: 1 1 auto;
}

.otp-box div div {
  flex: 1 1 auto;
}

.otp-box span {
  margin: 0px 5px;
}

.client-app .login-box {
  margin: 3% auto;
  box-shadow: none;
}

.client-app .login-box .nav-wrapper,
.bg-login-image .login-box .nav-wrapper {
  position: fixed;
  top: 30px;
  right: 30px;
}

.client-app .login-logo,
.client-app .register-logo {
  padding-top: 70px;
}

.client-app .login-logo,
.client-app .login-box-body {
  background-color: transparent;
}

.client-app .login-box-body .form-control {
  background: #fff !important;
  border-radius: 0;
  height: 40px;
  border: none;
  font-size: 16px;
  color: #473b6d;
}

.w-80p {
  width: 80%;
  margin: 0 auto;
}

.client-app .login-logo {
  position: relative;
  top: 0;
  max-width: 800px;
}

.btn-go {
  height: 70px;
}

.btn-signup {
  position: absolute;
  top: 70px;
  right: 35px;
  display: block;
}

.btn-signup img {
  height: 35px;
}

/* **************************************************************************************
    Register
*************************************************************************************** */
.bg-register-image {
  background: url("../img/register.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
}

.bg-register-image::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-register-box {
  padding: 30px 50px !important;
}

.h-100p {
  height: 100%;
}

.bottom-right-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.nav-wrapper {
  margin-bottom: 15px;
}

.sl-nav {
  display: inline;
}

.sl-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
}

.sl-nav li {
  cursor: pointer;
  padding-bottom: 10px;
}

.sl-nav li ul {
  display: none;
}

.sl-nav li:hover ul {
  position: absolute;
  top: 29px;
  right: -15px;
  display: block;
  background: #fff;
  width: 120px;
  padding-top: 0px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.sl-nav li:hover .triangle {
  position: absolute;
  top: 15px;
  right: -10px;
  z-index: 10;
  height: 14px;
  overflow: hidden;
  width: 30px;
  background: transparent;
}

.sl-nav li:hover .triangle:after {
  content: "";
  display: block;
  z-index: 20;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius: 2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.sl-nav li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-bottom: 0;
  z-index: 2;
  font-size: 15px;
  color: #3c3c3c;
}

.sl-nav li ul li:last-of-type {
  padding-bottom: 15px;
}

.sl-nav li ul li span {
  padding-left: 5px;
}

.sl-nav li ul li span:hover,
.sl-nav li ul li span.active {
  color: #146c78;
}

.sl-flag {
  display: inline-block;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  width: 15px;
  height: 15px;
  background: #aaa;
  border-radius: 50%;
  position: relative;
  top: 0px;
  right: 5px;
  overflow: hidden;
}

.flag-de {
  background: url("https://www.spintrak.com/wp-content/uploads/2019/03/flag-400.png");
  background-size: cover;
  background-position: center center;
}

.flag-usa {
  background-size: cover;
  background-position: center center;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTM0A1t6AAABhUlEQVQ4T2Ows82PjGixsc4LD2tysC/09Kjw8622tyuICG8u0w/cpGSCBzF4e1VmZkzw9anOzOj38a4KCW4IC22ECHYk1l9tn4gHMeTlTnZxLikvm+XiUpKW2hvgX+vnV5OVOQEoOGfOtv94AYOzU3Fd7XxHh6Lq6rlurqUx0W0J8Z1AnbW18yotonaYuOJBDBXls4A+bGpaBCTz86YEBtQCvVBSPAPIbY0oP1/aiAcxABU1Ny+2tclvbFjo5FgUF9uenNwNDLnmpkWEnV1TPRcY1O1tS4H6i4umA/0MDK2K8tlAwRqHpP1uoXgQKKraWpcClTY3LQZaCLQ5NaUX5OaWJY3++SeTC/AgBmA4AXUClUJs9ver8fKsAAYEUJCws4G21dXNB1oFdD/Qz8DQTk4C+bm2dn6DZ9bRiDQ8iAEYt8CoBpK5YBIYw0AEEZwSXX4oMB4PYoC6gCzAcDqrjGzEsMfen2xEmbMv1rSTjRi26dqRjShz9o2+6WQjBrSShQSkZAIADvW/HLrLY6cAAAAASUVORK5CYII=");
}

.custom-stepper .MuiStep-root {
  padding-top: 25px;
  padding-left: 0;
}

.custom-stepper .MuiStepLabel-label {
  font-size: 1rem;
}

.custom-stepper .MuiStepLabel-label,
.custom-stepper .MuiStepIcon-text {
  color: rgba(255, 255, 255, 0.8) !important;
  fill: rgba(255, 255, 255, 0.8) !important;
  font-family: inherit;
}

.custom-stepper .MuiStepLabel-label.Mui-active,
.custom-stepper .Mui-completed .MuiStepLabel-label {
  color: #fff !important;
}

.custom-stepper .MuiStepLabel-iconContainer {
  padding-right: 12px;
}

.custom-stepper .MuiSvgIcon-root {
  height: 30px;
  width: 30px;
  font-size: 1rem;
  text-align: center;
}

.custom-stepper .MuiSvgIcon-root.Mui-active,
.custom-stepper .MuiSvgIcon-root.Mui-completed {
  color: #68439a !important;
  font-weight: 500;
  background-color: #fff;
  border-radius: 100%;
}

.first-left-box {
  /* style={{ padding: "40px 80px 20px 80px" }} */
}

.we-start-list li {
  display: flex;
  flex-direction: row;
  align-items: start;
  font-size: inherit;
}

.we-start-list li i {
  width: 50px;
  margin-right: 15px;
}

/* **************************************************************************************
    Landing Page
*************************************************************************************** */

/* Navbar Brand */
.landing-page .navbar-brand img {
  height: 45px;
}

/* Navbar */
.landing-page .navbar {
  margin-bottom: 0px;
}

.landing-page .navbar-toggle {
  margin-top: 12px;
  margin-bottom: 0px;
  padding: 9px 8px;
}

.navbar-default .navbar-toggle {
  border-color: #006699;
}

.navbar-default .navbar-toggle:hover .icon-bar,
.navbar-default .navbar-toggle:focus .icon-bar {
  background-color: #fff;
}

/* Navbar Banner */
.navbar-banner {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link i {
  color: #fff !important;
  font-size: 16px;
}

.addstickyhere.sticky-menu.navbar-default.providerdetails .navbar-nav > li > a,
.navbar-banner.navbar-default .navbar-nav > li > a,
.navbar-banner.navbar-default .navbar-nav > .open > a {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.navbar-banner.navbar-default .navbar-nav > li > a:hover {
  text-decoration: none;
  opacity: 0.7;
  filter: alpha(opacity= "70");
}

.navbar-banner.navbar-default .navbar-toggle {
  border-color: #fff;
}

.navbar-banner.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-banner.navbar-default .navbar-toggle:hover,
.navbar-banner.navbar-default .navbar-toggle:focus {
  background-color: #fff;
}

.navbar-banner.navbar-default .navbar-toggle:hover .icon-bar,
.navbar-banner.navbar-default .navbar-toggle:focus .icon-bar {
  background-color: #393e4a;
}

video {
  width: 100%;
}

/* ********************************************************************************************
    Banner Slider
*********************************************************************************************** */

/* Banner */
.full-screen-banner {
  /*overflow-x: hidden;    */
  height: 100vh;
  width: 100%;
  position: relative;
}

.full-screen-banner:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.65);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#65000000, endColorstr=#65000000);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#65000000, endColorstr=#65000000)";
  -webkit-transform: translateZ(0);
  /*display: table;*/
  width: 100%;
  text-align: center;
  content: "";
  display: none;
}

/* .full-screen-banner .item-image{
    background-size: cover !important;
    width: 100%;
    height: 100%;
} */
/* .full-screen-video .item{
    background: url("../img/video-banner.png") no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;
} */
.full-screen-banner video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: 1s opacity;
  transition: 1s opacity;
  object-fit: cover;
}

.full-screen-video:before {
  background: rgba(0, 0, 0, 0.3);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#30000000, endColorstr=#30000000);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#30000000, endColorstr=#30000000)";
}

#myvideo_mobile {
  display: none;
}

.play-video-icon {
  position: absolute;
  bottom: 25%;
  z-index: 2;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: none;
}

.play-video-icon .fa {
  color: #fff;
  font-size: 40px;
}

video:not(.notification-video)::-webkit-media-controls {
  display: none !important;
}

video:not(.notification-video)::-webkit-media-controls-start-playback-button {
  display: none !important;
}

/* Content */
.banner-caption {
  object-fit: cover;
  color: #fff;
  z-index: 2;
  width: 100%;
  text-align: center;
}

.banner-caption .heading {
  font-size: 65px;
  margin-bottom: 15px;
  color: #fff;
  font-family: "beyond_the_mountainsregular";
}

.banner-caption p {
  font-size: 21px;
  color: #fff;
}

.home-provider {
  background: #68439a url(../img/pattern_fill.png) repeat;
  color: #fff;
  text-align: left;
  font-size: 18px;
  padding: 50px 0;
}

.home-provider .become {
  font-size: 55px;
  line-height: 55px;
  font-family: "beyond_the_mountainsregular";
  display: block;
}

.home-provider .smiley {
  font-size: 35px;
}

.home-provider .signup-provider {
  color: #68439a;
  background-color: #fff;
  border: 1px solid transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 15px 25px;
  font-weight: 700;
  font-size: 25px;
  display: inline-block;
}

.home-provider .signup-provider:hover {
  background: #68439a;
  border: 1px solid #fff;
  color: #fff;
}

.home-provider .signup-provider i {
  color: #68439a;
}

.home-provider .signup-provider:hover i {
  color: #fff;
}

.home-description {
  padding: 90px 0 80px;
  background: #fff;
}

.home-description p {
  font-size: 21px;
}

.home-description p span.title {
  font-size: 70px;
  line-height: 70px;
  color: #68439a;
  font-family: "blanche_de_la_fontaineregular";
  float: left;
  padding-right: 30px;
}

.home-description p b {
  color: #68439a;
}

.banner-scroller {
  display: block;
  text-align: center;
  z-index: 2;
  position: absolute;
  width: 100%;
  bottom: -30px;
  cursor: pointer;
}

.banner-scroller .rounded-bg {
  background: #fff;
  display: inline-block;
  padding: 7px 11px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

/* ********************************************************************************************
    Footer
*********************************************************************************************** */

footer {
  background-color: #2b343e;
  font-size: 15px;
}

.footer a,
.footer p,
.footer i {
  color: #fff;
  font-size: 16px;
}

.footer li {
  padding: 6px 0px;
  line-height: normal;
  color: #fff;
  font-size: 16px;
}

.footer a:hover,
.footer a:focus {
  color: #c69bff;
}

.footer-top {
  padding: 40px 0px 40px;
}

.footer-top .heading h4 {
  color: #fff;
  font-size: 21px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-top .heading hr {
  border-width: 2px;
  max-width: 30px;
  margin: 10px 0px 0px;
  border-color: #006699;
}

.footer-bottom {
  padding: 15px 0px;
  font-weight: 600;
}

.footer-bottom p {
  margin: 0px;
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
}

.footer-bottom-container {
  background: #1c2127;
}

.social-icon {
  font-size: 30px;
}

.footer .social-icon li {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  display: inline-block;
  margin: 0 5px;
}

.footer .social-icon a {
  color: #fff;
  font-size: 22px;
}

.footer .social-icon a:hover,
.footer .social-icon a:focus {
  color: #bfbfbf;
}

.footer .social-icon li a i {
  font-size: 22px;
}

.followfred {
  display: inline-block;
  margin-right: 10px;
  font-weight: 600;
}

ul.legalcontainer {
  margin-top: -38px;
  margin-bottom: -30px;
  margin-left: 55px;
  list-style: none;
  width: 150px;
  padding: 5px 5px 7px 0;
  background: #0580c6;
  border-radius: 5px;
  text-align: center;
}

ul.legalcontainer:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #0580c6;
  position: relative;
  float: left;
  left: -9px;
  top: 15px;
}

ul.legalcontainer li {
  padding: 4px 0px;
}

ul.legalcontainer li a {
  font-size: 14px;
}

/* **************************************************************************************
    Modal
*************************************************************************************** */
.modal-dialog {
  height: 100%;
  margin: 0px auto;
  padding: 60px 0px;
}

.modal-dialog > form {
  height: 100%;
}

.modal-content {
  border-radius: 3px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 15px 20px;
}

.modal-body {
  height: 400px;
}

.modal-header {
  min-height: 55px;
}

.modal-footer {
  min-height: 70px;
}

.modal-header .close {
  margin-top: -4px;
  font-size: 25px;
  font-family: arial;
  padding: 0;
  padding-right: 1rem;
  padding-top: 0.3rem;
}

.modal-title {
  font-weight: 500;
}

.modal-body {
  overflow-y: auto;
}

.modal-body .nav.nav-tabs li a {
  font-size: 0.875rem;
  height: 50px;
  padding: 1rem 1.5rem 1rem;
  line-height: 1.2;
}

.modal-footer {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.modal-dialog-notification {
  height: auto;
  width: auto;
}

.modal-body-notification {
  overflow-y: auto;
  position: relative;
  padding: var(--bs-modal-padding);
}

/* ********************************************************************************************
    Chart
*********************************************************************************************** */

.pie-chart-legend .table {
  font-size: 16px;
  margin: 0px auto;
  max-width: 340px;
  white-space: nowrap;
}

.pie-chart-legend .table .colorbox,
.chart-legend ul > li > span {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: 0px;
  margin-right: 10px;
}

.pie-chart-legend .table .value {
  font-weight: 600;
  color: #525e70;
}

.chart-legend ul {
  list-style: none;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  float: right;
}

.chart-legend ul li {
  display: inline-block;
  margin-left: 20px;
}

.chart-legend ul > li > span {
  margin-right: 10px;
}

/* ********************************************************************************************
    Map
*********************************************************************************************** */

.map-sidebar,
.filter-sidebar {
  width: 320px;
  min-width: 320px;
  height: calc(100vh - 65px);
  display: none;
  transition: all 0.3s;
}

.map-sidebar {
  height: calc(100vh - 140px);
}

.map-sidebar .card-body {
  height: calc(100vh - 196px);
}

.filter-sidebar .card-body {
  height: calc(100vh - 191px);
}

.map-list li {
  padding: 1rem;
  border-left: 3px solid transparent !important;
  border-bottom: 1px solid #eaf3fd !important;
}

.map-list li.active {
  border-left: 3px solid #fab854 !important;
  background-color: rgba(250, 184, 84, 0.11);
}

.map-sidebar-btn,
.filter-sidebar-btn {
  position: fixed;
  top: 140px;
  left: 0px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #ee3124;
  z-index: 9;
  display: block;
  transition: all 0.3s;
}

.filter-sidebar-btn {
  left: auto;
  right: 0px;
  background-color: #fff;
}

.map-sidebar-btn.active,
.filter-sidebar-btn.active {
  display: none;
}

.map-sidebar-btn.active ~ .map-sidebar,
.filter-sidebar-btn.active ~ .filter-sidebar {
  display: block;
}

.filter-sidebar .DateRangePicker {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.filter-sidebar .DateRangePickerInput {
  width: 100%;
}

.SingleDatePicker {
  flex: 1 1 auto;
}

.SingleDatePicker .SingleDatePickerInput {
  display: block;
}

.SingleDatePicker .DateInput {
  width: 100% !important;
}

.SingleDatePicker .DateInput_input__focused {
  border-bottom: none !important;
}

.driver-view-map-image {
  height: 400px;
}

/* ********************************************************************************************
    Autocomplete
*********************************************************************************************** */

.autocomplete-suggestions {
  border: 1px solid rgba(120, 141, 180, 0.3);
  background: #fff;
  cursor: default;
  overflow: auto;
}

.autocomplete-suggestion {
  padding: 3px 10px;
}

.autocomplete-suggestion:hover {
  background: #5accca;
  cursor: pointer;
  color: #fff;
}

/* ********************************************************************************************
    Accordion
*********************************************************************************************** */

.accordion > .card > .card-header .btn-link {
  padding: 6px 125px 6px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.accordion > .card > .card-header .btn-link:after {
  right: 8.5%;
  top: 50%;
}

.assign-timeslot.accordion > .card > .card-header .btn-link {
  padding: 6px 20px 6px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.assign-timeslot.accordion > .card > .card-header .btn-link:after {
  right: 2.5%;
  top: 50%;
}

/* ********************************************************************************************
    Date Picker
*********************************************************************************************** */
.DateInput {
  width: 90px !important;
}

.DateInput_input {
  font-size: 12px !important;
  padding: 0.375rem 0.75rem !important;
  height: 33px;
}

.DateInput_input__focused {
  border-bottom: 2px solid #68439a !important;
}

.DateRangePickerInput__withBorder {
  border: 1px solid #dfe7f3 !important;
  border-radius: 0.25rem !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #68439a !important;
  border: 1px double #68439a !important;
}

.CalendarDay__selected_span {
  background: #68439a80 !important;
  border: 1px double #68439a80 !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #68439a80 !important;
  border: 1px double #68439a80 !important;
  color: #484848 !important;
}

.DateRangePickerInput_arrow_svg {
  height: 19px !important;
  width: 19px !important;
  margin-top: -3px;
  margin-left: -3px;
}

/* ********************************************************************************************
    CK editor
*********************************************************************************************** */
.ck-content {
  min-height: 250px;
}

.ck.ck-toolbar {
  border: 1px solid #dfe7f3 !important;
  border-bottom: none !important;
}

.ck.ck-content.ck-editor__editable {
  border-color: #dfe7f3 !important;
  box-shadow: none !important;
}

.ck.ck-content.ck-editor__editable:focus {
  border-color: #68439a !important;
}

.ck.ck-list__item .ck-button.ck-on {
  background: #68439a !important;
}

.ck.ck-list__item .ck-button:active {
  border: none !important;
}

.ck.ck-dropdown__panel {
  border: 1px solid #dfe7f3 !important;
  box-shadow: none !important;
}

.ck.ck-dropdown .ck-dropdown__arrow {
  z-index: 0 !important;
}

/* ********************************************************************************************
    React Select Dropdown
*********************************************************************************************** */
.custom-select-dropdown {
  width: 220px;
  margin: 0 auto;
}

.custom-select-dropdown,
.react-select-dropdown__option {
  cursor: pointer;
}
.react-select-dropdown__menu {
  z-index: 2 !important;
}
.custom-select-dropdown span + span + div {
  border-color: #dfe7f3 !important;
  height: inherit;
  min-height: 35px;
  max-height: auto;
  box-shadow: none;
  font-size: 15px;
  cursor: pointer;
}

.custom-select-dropdown span + span + div:hover {
  border-color: #f7b500;
}

.custom-select-dropdown span + span + div div span {
  display: none;
}

.custom-select-dropdown span + span + div div span + div {
  height: 15px;
  width: 15px;
  padding: 0;
  margin-right: 10px;
  margin-top: -4px;
}

.custom-select-dropdown span + span + div div span + div svg {
  color: #617182;
}

.custom-select-dropdown span + span + div > div > div {
  font-size: 0.875rem;
  margin-left: 5px;
  color: #617182;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-select-dropdown span + span + div + div {
  font-size: 0.875rem;
  border: 1px solid #dfe7f3;
  box-shadow: 0 4px 14px rgba(174, 197, 231, 0.5);
}

.custom-select-dropdown span + span + div input {
  height: 20px;
}

/* .react-select-dropdown__option:hover {
    color: #000;
    background-color: rgba(231, 241, 253, 0.2) !important;
}

.react-select-dropdown__option.react-select-dropdown__option--is-selected {
    background-color: #68439a;
} */

.custom-select-dropdown.multi span + span + div + div {
  border: none;
  box-shadow: none;
}

/* ********************************************************************************************
    Table Thead Fix
*********************************************************************************************** */
.table-thead-fix {
  max-height: 450px;
}

.table-thead-fix thead th {
  position: sticky;
  top: 0;
  font-weight: 600;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #ffffff;
  z-index: 1;
  border-bottom: none !important;
}

.table-thead-fix thead th::before,
.table-thead-fix thead th::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

.table-thead-fix thead th::after {
  bottom: 0px;
  border-bottom: 1px solid rgba(234, 243, 253, 0.9);
}

.table-thead-fix tbody tr:first-child td {
  border-top: none !important;
}

/* ********************************************************************************************
    Register View Form
*********************************************************************************************** */
.top-box {
  max-width: 200px;
}

.register-view-form,
.register-view-form .form-control,
.register-view-form .form-control-plaintext,
.register-view-form label,
.register-view-form p,
.register-view-form h4,
.register-view-form h6,
.register-view-form li {
  color: #0c0c0c !important;
  font-size: 12px !important;
}

.register-view-form h4,
.register-view-form h6 {
  font-size: 13px !important;
}

.register-view-form label {
  display: block;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 11px !important;
}

.register-view-form .form-control,
.register-view-form .form-control-plaintext {
  padding: 0.25rem 0 0.375rem;
  max-height: 22px;
  text-align: center;
  border: 1px solid #000;
  border-top: none;
  /* text-transform: uppercase; */
  border-radius: 0;
}

.register-view-form .no-gutters .col {
  margin-right: -1px;
}

.register-view-form .form-group {
  margin-bottom: 0;
}

.register-view-form .card {
  border: 1px solid #000 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  margin-bottom: 15px;
}

.register-view-form .card-body {
  padding: 8px;
  border-bottom: 1px solid #000 !important;
}

.register-view-form div.card-body.border-bottom {
  border-bottom: 1px solid #000 !important;
}

.register-view-form .border-right {
  border-right: 1px solid #000 !important;
}

.register-view-form .card-body:last-child {
  border-bottom: none !important;
}

.register-view-form .control {
  text-align: left;
  padding-left: 20px;
  pointer-events: none;
  font-size: 12px !important;
}

.register-view-form .control.control-outline .control__indicator {
  top: 2px;
  width: 14px;
  height: 14px;
  border-radius: 0px;
  border: 1px solid #000;
}

.register-view-form
  .control.control-outline.control-primary
  input:checked
  ~ .control__indicator {
  border: 1px solid #000;
}

.register-view-form
  .control.control-outline.control-primary.control--checkbox
  .control__indicator:after {
  border-color: #000;
}

.register-view-form
  .control.control-outline.control--checkbox
  .control__indicator:after {
  top: 0px;
  left: 4px;
  width: 5px;
  height: 9px;
}

.register-view-form
  .control.control-outline
  input:disabled
  ~ .control__indicator {
  background: transparent;
  opacity: 1;
}

.register-view-form
  .control.control-outline.control--checkbox
  input:disabled
  ~ .control__indicator:after {
  border-color: #000 !important;
}

.register-view-form .side-border-0 {
  border-left: none;
  border-right: none;
}

/* ********************************************************************************************
    React Select Dropdown
*********************************************************************************************** */
.custom-select-dropdown,
.react-select-dropdown__option {
  cursor: pointer;
}

.custom-select-dropdown span + span + div {
  border-color: #dfe7f3 !important;
  height: inherit;
  min-height: 35px;
  max-height: auto;
  box-shadow: none;
  font-size: 15px;
  cursor: pointer;
}

.custom-select-dropdown span + span + div:hover {
  border-color: #f7b500;
}

.custom-select-dropdown span + span + div div span {
  display: none;
}

.custom-select-dropdown span + span + div div span + div {
  height: 15px;
  width: 15px;
  padding: 0;
  margin-right: 10px;
  margin-top: -4px;
}

.custom-select-dropdown span + span + div div span + div svg {
  color: #617182;
}

.custom-select-dropdown span + span + div > div > div {
  font-size: 0.875rem;
  margin-left: 5px;
  color: #617182;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-select-dropdown span + span + div + div {
  font-size: 0.875rem;
  border: 1px solid #dfe7f3;
  box-shadow: 0 4px 14px rgba(174, 197, 231, 0.5);
}

.custom-select-dropdown span + span + div input {
  height: 20px;
}

/* .react-select-dropdown__option:hover {
    background-color: rgba(231, 241, 253, 0.2) !important;
    color: black;
}

.react-select-dropdown__option.react-select-dropdown__option--is-selected {
    background-color: #68439a;
} */

.custom-select-dropdown.multi span + span + div + div {
  border: none;
  box-shadow: none;
}

/* ********************************************************************************************
    Others
*********************************************************************************************** */
.inner-filter select.form-control:not([size]):not([multiple]) {
  height: calc(2.0625rem + 2px);
}

.inner-filter .btn {
  height: 35px;
  line-height: 9px;
}

.cms-page p {
  font-size: 15px;
}

.cms-page ul li,
.cms-page ol li {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.text-underline {
  text-decoration: underline !important;
}

.customer-list li .customer-name {
  margin-left: 15px;
}

.modal-dialog-scrollable .modal-body {
  max-height: calc(100vh - 150px);
}

i.v-align-1 {
  vertical-align: 1px !important;
}

.top-1 {
  position: relative;
  top: 1px !important;
}

.border-bottom-none .table tbody tr:last-child td {
  border-bottom: none;
}

#filter-form label {
  font-size: 13px !important;
}

.form-control-plaintext {
  pointer-events: none;
}

.no-arrow.dropdown-toggle::after {
  display: none;
}

.date-time {
  font-size: 12px;
  color: #ffce67;
  margin-right: 15px;
  line-height: 67px;
}

.inner-page-date .DateRangePicker {
  flex: 1 1 auto !important;
}

.inner-page-date .DateRangePickerInput,
.inner-page-date.schedule .DateRangePicker {
  width: 100% !important;
}

.inner-page-date.schedule .DateInput {
  width: 80px !important;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid #dfe7f3 !important;
}

.actions.top-right.inner-tab {
  top: 78px;
}

.actions.top-right.inner-tab.minus-tab {
  top: 74px;
}

.noti-count {
  position: absolute;
  top: 16px;
  right: 18px;
  font-size: 11px;
  background-color: #68439a;
  height: 16px;
  width: 16px;
  line-height: 16px;
  color: #fff;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.react-seleact-dropdown__option.react-seleact-dropdown__option--is-selected {
  background-color: #000;
}

.set-pricing-tiers .col {
  min-width: 165px;
}

.set-pricing-tiers .index-price-col {
  min-width: 120px;
  max-width: 120px;
}

.table-th-td-fix.table-responsive {
  max-height: calc(100vh - 250px);
}

.table-th-td-fix thead th {
  position: sticky;
  top: 0;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #ffffff;
  z-index: 1;
  border-bottom: none !important;
}

.table-th-td-fix thead th::after,
.table-th-td-fix thead th::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

.table-th-td-fix thead th::before {
  bottom: 0px;
  border-bottom: 1px solid rgba(234, 243, 253, 0.9);
}

.table-th-td-fix tbody tr:first-child td {
  border-top: none !important;
}

.table-th-td-fix thead th:last-child,
.table-th-td-fix tbody td:last-child {
  min-width: 120px;
}

.sticky-col {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  background-color: #ffff !important;
}

.table-th-td-fix th.sticky-col {
  z-index: 2 !important;
}

.first-col {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  left: 0;
}

.second-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 120px;
}

.third-col {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  left: 270px;
}

.fourth-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 390px;
}

.fifth-col {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  left: 540px;
}

.table-th-td-fix.propane-tank .fifth-col {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  height: 6px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

/* .tab-custom-border {
    display: table-cell;
    line-height: 15px !important;
} */
.text-error {
  font-size: 12px;
  color: red;
  margin-left: 14px;
  font-weight: 500;
}

.text-error-login {
  font-size: 12px;
  color: red;
  margin-left: 0px;
  margin-top: 3px;
  font-weight: 600;
}

.error {
  font-size: 12px;
  color: red;
  font-weight: 500;
}

.vps-machine-table.table.table-bordered,
.vps-machine-table.table.table-bordered td,
.vps-machine-table.table.table-bordered th,
.vps-machine-table.table.table-bordered th::after,
.vps-machine-table.table.table-bordered thead th {
  border: 1px solid rgb(0 0 0 / 60%);
  color: #000;
}

.vps-machine-table .bg-danger {
  background-color: #ffff !important;
}

.vps-machine-table .btn-danger {
  background-color: #ff2d01 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 400;
}

.vps-machine-table .btn-default {
  background-color: lightgray !important;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 400;
}

.btn-danger:hover,
a:link.btn-danger:hover {
  border: 1px solid #ff2d01;
  box-shadow: 0 2px 5px rgb(255 45 1 / 40%) !important;
  background-color: #ff2d01 !important;
}

.vps-machine-table.table.table-bordered th {
  color: #fff !important;
}

.vps-machine-table.table.table-bordered th.text-black {
  color: #000 !important;
}

.vps-machine-table thead th {
  font-size: 1rem;
  font-weight: normal;
  line-height: normal;
}

.vps-machine-table tr td {
  font-weight: 400 !important;
  max-width: 200px;
}

.vps-machine-table td:not(:last-child),
.vps-machine-table th:not(:last-child) {
  border-right: 2px solid rgb(176, 163, 163);
}

.vps-arrow {
  font-size: 24px;
  color: #000;
  vertical-align: -4px;
}

.vps-check {
  color: #41a61a;
  font-size: 20px;
}

.vps-check.text-danger {
  color: #ff2d01 !important;
}

.lh-24 {
  line-height: 24px !important;
}

.custom-switch .MuiSwitch-track {
  background-color: #fff;
}

.custom-switch .MuiSwitch-switchBase.Mui-checked {
  color: #68439a;
}

.custom-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #68439a;
}

.client-dashboard .content-wrapper .content {
  padding: 0 !important;
  margin: 0 !important;
}

.client-dashboard .content-wrapper .page-header,
.client-dashboard .content-wrapper .page-content {
  padding: 0 !important;
}

.client-dashboard .content-wrapper .page-content {
  padding-top: 20px !important;
}

.client-dashboard .content-wrapper .page-content .card-body {
  padding: 0 !important;
}

.no-shadow {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.dashboard-gifts-card .badges,
.dashboard-class-card .badges {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  text-align: center;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0;
  font-size: 0.813rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease;
  vertical-align: middle;
  font-weight: 400;
  margin-right: 0.75rem !important;
  margin-bottom: 0.75rem !important;
  color: #2b2b2b;
  min-width: 100px;
  min-height: 100px;
  max-width: 200px;
}
.dashboard-class-card .badges img,
.dashboard-gifts-card .badges img {
  display: block;
  height: 70px;
  width: 100%;
  object-fit: contain;
  border-radius: 3px 3px 0 0 !important;
}
.dashboard-class-card .badges .badge-class,
.dashboard-gifts-card .badges .badge-class {
  padding: 5px 7px;
  display: block;
}

.dashboard-gifts-card .card-box,
.dashboard-class-card .card-box {
  display: flex;
  overflow-x: auto;
}

.dashboard-gifts-card .read-more,
.dashboard-class-card .read-more {
  font-size: 14px;
  font-weight: 300;
  background-color: transparent;
  padding: 8px 15px;
  border-radius: 35px;
  color: #000 !important;
}

.dashboard-gifts-card .read-more:hover,
.dashboard-class-card .read-more:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* .dashboard-class-card {
    background-color: #736cc7 !important;
} */

/* .dashboard-gifts-card {
    background-color: #F64A91 !important;
} */

/* ********************************************************************************************
    Extara css
*********************************************************************************************** */
.pag > .pagination {
  margin-left: 35px !important;
  justify-content: start !important;
}

.equal-height {
  height: 100%;
}

.css-1kqosj6-MuiChip-root {
  color: white !important;
  background-color: gray !important;
}

.rdw-editor-main {
  height: 150px;
  overflow-y: auto;
}

.notification-alert-editor .editor {
  min-height: auto !important;
}

.notification-alert-editor .rdw-editor-main {
  height: auto;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.show-uplod-img .show-image {
  position: relative;
  border: solid 1px #ddd;
  padding: 15px;
}

.show-uplod-img .show-image .delete {
  border: solid 1px #ddd;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 6px;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.notification-alert-editor .rdw-editor-main {
  padding: 0 !important;
}

.notification-alert-editor
  .rdw-editor-main
  .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  text-align: justify;
}

.notification-video-image {
  height: 300px;
}

.modal-content .rounded.img-thumbnail {
  object-fit: cover;
  height: 100% !important;
  text-align: left;
  border: 0;
  padding: 0;
  width: 100%;
}

.react-tel-input .form-control {
  width: 100%;
}

.signature-canvas-bg {
  /* width: 100% !important; */
  height: 170px !important;
  border: 1px solid #dfe7f3;
}
.signature-canvas-bg canvas {
  width: 100%;
  height: 100%;
}

/* .signature-canvas-bg .sigCanvas {
    width: 100% !important;
    height: 100% !important;
} */

.editor-state-change {
  width: 93%;
}

.advertisement-body {
  width: 100% !important;
  height: 400px !important;
}

.advertisement-images {
  width: 100% !important;
  height: 50% !important;
  object-fit: contain !important;
  border: 1px solid rgb(243, 243, 243) !important;
  border-radius: 5px !important;
  padding: 10px !important;
}
.qr-code-box-image-container svg,
.qr-code-image svg {
  width: 100%;
}
div.student-class-reg {
  border-bottom: 1px solid #e3e3e3 !important;
}
div.student-class-reg:last-child {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.student-class-reg .rdw-editor-main {
  height: auto;
  padding: 0 !important;
}
.student-class-ad-image {
  height: 150px !important;
  width: 250px !important;
  object-fit: cover;
}
/* ********************************************************************************************
    Dark Theme
*********************************************************************************************** */
body.dark-theme {
  background-color: #000000;
  color: #c9d1d9;
}

body.dark-theme .sidebar-left,
body.dark-theme .sidebar-left .sidebar-content {
  background-color: #090c10;
}

body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  li
  a:hover,
body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  li
  a:hover
  i,
body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown
  > ul
  > li
  > a
  > span
  > i,
body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown
  > ul
  > li
  > a
  > span
  > span {
  color: #a78bcc !important;
}

body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown
  > ul
  > li.nav-dropdown
  > ul
  > li
  > a,
body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown
  > ul
  > li
  > a
  > span
  > i,
body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown
  > ul
  > li
  > a
  > span
  > span,
body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown
  > ul
  > li.nav-dropdown
  > ul
  > li
  > a
  > i,
body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li
  > a,
body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li
  > a
  > span
  > i,
body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li
  > a
  > i {
  color: #c9d1d9 !important;
}

body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a
  i,
body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a {
  color: #a78bcc !important;
}

body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .sidebar-header {
  color: #fff !important;
}

.dark-theme .h1,
.dark-theme .h2,
.dark-theme .h3,
.dark-theme .h4,
.dark-theme .h5,
.dark-theme .h6,
.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6 {
  color: rgb(255 255 255 / 90%);
}

.dark-theme .card,
.dark-theme .card .card-footer {
  background: transparent !important;
  border-color: #30363d !important;
}

.dark-theme .MuiPaper-root,
.dark-theme .modal-content {
  background-color: #0d1117;
  border: 1px solid #30363d;
  color: #c9d1d9;
  box-shadow: none;
}

.dark-theme .blockquote-footer,
.dark-theme .text-muted {
  color: #617182 !important;
}

.dark-theme .dropdown .dropdown-menu .dropdown-header,
.dark-theme .dropleft .dropdown-menu .dropdown-header,
.dark-theme .dropright .dropdown-menu .dropdown-header,
.dark-theme .dropup .dropdown-menu .dropdown-header {
  color: #c9d1d9;
  border-bottom: 1px solid #30363d;
}

.dark-theme .card .card-header {
  border-bottom: 1px solid #30363d;
}

.dark-theme .pagination .page-item.disabled .page-link,
.dark-theme .pagination .page-link,
.dark-theme i,
.dark-theme .MuiButtonBase-root {
  color: #c9d1d9 !important;
}

.dark-theme .MuiButtonBase-root.Mui-selected {
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
}

.dark-theme .vps-machine-table .bg-danger {
  background-color: #0d1117 !important;
}

.dark-theme .vps-machine-table.table.table-bordered th.text-black {
  color: #c9d1d9 !important;
}

.dark-theme .vps-machine-table.table.table-bordered,
.dark-theme .vps-machine-table.table.table-bordered td,
.dark-theme .vps-machine-table.table.table-bordered th,
.dark-theme .vps-machine-table.table.table-bordered th::after,
.dark-theme .vps-machine-table.table.table-bordered thead th {
  color: #c9d1d9 !important;
  border: 1px solid #30363d;
}

.dark-theme .qr-code-box svg {
  fill: #fff;
}

.dark-theme .dropdown-menu,
.dark-theme .sl-nav li ul li {
  background-color: #0d1117;
  box-shadow: 0 2px 7px rgb(174 197 231 / 25%);
  color: #c9d1d9;
}

.dark-theme .sl-nav li ul li span:hover,
.dark-theme .sl-nav li ul li span.active {
  color: #fff;
}

.dark-theme .sl-nav li:hover .triangle:after {
  background-color: #0860e3;
}

.dark-theme .custom-file-input:focus ~ .custom-file-label:after,
.dark-theme .custom-file-label,
.dark-theme .custom-file-label:after,
.dark-theme .form-control {
  border-color: #090c10;
}

body.dark-theme .top-toolbar.navbar-desktop {
  background: #161b22;
}

.dark-theme .dropdown .dropdown-menu .dropdown-item,
.dark-theme .dropleft .dropdown-menu .dropdown-item,
.dark-theme .dropright .dropdown-menu .dropdown-item,
.dark-theme .dropup .dropdown-menu .dropdown-item {
  color: #c9d1d9;
}

.dark-theme .dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.06);
}

.dark-theme .dropdown .dropdown-menu .dropdown-item.active,
.dark-theme .dropdown .dropdown-menu .dropdown-item:active,
.dark-theme .dropdown .dropdown-menu .dropdown-item:focus,
.dark-theme .dropdown .dropdown-menu .dropdown-item:hover,
.dark-theme .dropleft .dropdown-menu .dropdown-item.active,
.dark-theme .dropleft .dropdown-menu .dropdown-item:active,
.dark-theme .dropleft .dropdown-menu .dropdown-item:focus,
.dark-theme .dropleft .dropdown-menu .dropdown-item:hover,
.dark-theme .dropright .dropdown-menu .dropdown-item.active,
.dark-theme .dropright .dropdown-menu .dropdown-item:active,
.dark-theme .dropright .dropdown-menu .dropdown-item:focus,
.dark-theme .dropright .dropdown-menu .dropdown-item:hover,
.dark-theme .dropup .dropdown-menu .dropdown-item.active,
.dark-theme .dropup .dropdown-menu .dropdown-item:active,
.dark-theme .dropup .dropdown-menu .dropdown-item:focus,
.dark-theme .dropup .dropdown-menu .dropdown-item:hover {
  color: #fff !important;
}

.dark-theme .dropdown .dropdown-menu .dropdown-item.active i,
.dark-theme .dropdown .dropdown-menu .dropdown-item:active i,
.dark-theme .dropdown .dropdown-menu .dropdown-item:focus i,
.dark-theme .dropdown .dropdown-menu .dropdown-item:hover i,
.dark-theme .dropleft .dropdown-menu .dropdown-item.active i,
.dark-theme .dropleft .dropdown-menu .dropdown-item:active i,
.dark-theme .dropleft .dropdown-menu .dropdown-item:focus i,
.dark-theme .dropleft .dropdown-menu .dropdown-item:hover i,
.dark-theme .dropright .dropdown-menu .dropdown-item.active i,
.dark-theme .dropright .dropdown-menu .dropdown-item:active i,
.dark-theme .dropright .dropdown-menu .dropdown-item:focus i,
.dark-theme .dropright .dropdown-menu .dropdown-item:hover i,
.dark-theme .dropup .dropdown-menu .dropdown-item.active i,
.dark-theme .dropup .dropdown-menu .dropdown-item:active i,
.dark-theme .dropup .dropdown-menu .dropdown-item:focus i,
.dark-theme .dropup .dropdown-menu .dropdown-item:hover i {
  color: #fff !important;
}

.dark-theme .form-control {
  border: 1px solid #30363d;
  background-color: #090c10;
  color: #fff;
}

.dark-theme .control.control-outline input:checked:focus ~ .control__indicator,
.dark-theme
  .control.control-outline:hover
  input:not([disabled]):checked
  ~ .control__indicator {
  background: transparent;
}

.dark-theme .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.dark-theme .table,
.dark-theme p,
.dark-theme i {
  color: #fffefe;
  /*#c9d1d9;*/
}

.dark-theme .table-hover tbody tr:hover {
  color: #fffefe;
  /*#c9d1d9;*/
  background-color: rgb(0 0 0 / 20%);
}

.dark-theme .table td,
.dark-theme .table th,
.dark-theme .table thead th {
  border-bottom: 1px solid #30363d;
}

.dark-theme .modal-footer {
  border-top: 1px solid #30363d;
}

body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .metismenu
  .nav-sub
  li
  > a,
body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .metismenu
  .nav-sub
  li
  > a
  > i {
  color: #c9d1d9;
}

body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .metismenu
  > li
  > a:focus {
  background-color: transparent;
}

body.dark-theme .aside-toolbar,
body.dark-theme .top-toolbar.navbar-mobile.navbar-tablet {
  background-color: #161b22;
}

body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > .nav-dropdown.active
  > .nav-sub {
  background: transparent !important;
}

body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > .nav-dropdown.active
  > .nav-sub
  li
  .active
  span {
  color: #a78bcc !important;
}

body.dark-theme
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > .nav-dropdown.active
  > .nav-sub
  li
  .active
  svg
  path {
  color: #a78bcc !important;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

.dark-theme .loading-spinner {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #da4f64;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.dark-theme .loader-container {
  background-color: rgba(255, 255, 255, 0.25);
}

.dark-theme .loader-container .spinner > div {
  background-color: #fff;
  border: 1px solid #fff;
}

.dark-theme .Mui-disabled {
  color: rgb(255 255 255 / 50%) !important;
}

.dark-theme .css-132vamb-MuiChip-root {
  color: rgb(255 255 255 / 87%);
}

.dark-theme .css-b62m3t-container > div {
  border: 1px solid #30363d !important;
  background-color: #090c10;
  color: #fff;
}

.dark-theme .react-select-dropdown__option {
  background-color: #090c10 !important;
  color: #fff !important;
}

.dark-theme .react-select-dropdown__option:hover {
  background-color: #fff !important;
  color: #090c10 !important;
}

.dark-theme .css-b62m3t-container .css-1n7v3ny-option {
  background-color: transparent !important;
}

.dark-theme .css-b62m3t-container .css-9gakcf-option,
.dark-theme
  .css-b62m3t-container
  .css-9gakcf-option.react-select-dropdown__option--is-selected {
  background-color: #ffffff !important;
  color: #000 !important;
}

.dark-theme .css-b62m3t-container .css-6j8wv5-Input,
.dark-theme .css-b62m3t-container .css-qc6sy-singleValue {
  color: #fff;
}

.dark-theme .css-1okebmr-indicatorSeparator {
  background-color: rgb(204 204 204 / 50%);
}

.dark-theme .css-tlfecz-indicatorContainer path {
  fill: rgb(204 204 204 / 50%);
}

.dark-theme .form-group .css-6j8wv5-Input,
.dark-theme .form-group .css-14el2xx-placeholder {
  color: #fff;
}

.dark-theme .text-dark {
  color: #fff !important;
}

.dark-theme .register-view-form .form-control,
.dark-theme .register-view-form .form-control-plaintext {
  border: 1px solid #919191;
}

.dark-theme .register-view-form,
.dark-theme .register-view-form .form-control,
.dark-theme .register-view-form .form-control-plaintext,
.dark-theme .register-view-form label,
.dark-theme .register-view-form p,
.dark-theme .register-view-form h4,
.dark-theme .register-view-form h6,
.dark-theme .register-view-form li {
  color: #ffffff !important;
}

.dark-theme
  .register-view-form
  .control.control-outline.control-primary.control--checkbox
  .control__indicator:after,
.dark-theme
  .register-view-form
  .control.control-outline
  input:disabled
  ~ .control__indicator {
  border-color: #919191 !important;
}

.dark-theme .MuiChip-outlinedDefault {
  color: #fff !important;
}

.dark-theme .common-box .login-logo,
.dark-theme .common-box .login-box-body,
.dark-theme .common-box .login-box-footer {
  background-color: #090c10;
}
.dark-theme .common-box .form-control,
.dark-theme .client-app.login-page .login-box-body .form-control {
  background-color: #090c10 !important;
}
.dark-theme .client-app.login-page .login-box-body .form-control {
  color: #fff !important;
}
/* .dark-theme .common-box .form-control::-moz-selection,
.dark-theme .client-app.login-page .login-box-body .form-control::-moz-selection {
    color: #000;
    background: #fff;
}
.dark-theme .common-box .form-control::selection,
.dark-theme .client-app.login-page .login-box-body .form-control::selection {
    color: #000;
    background: #fff;
} */
.dark-theme .common-box .login-box-footer.border-top {
  border-top: 1px solid #30363d !important;
}
.dark-theme .bg-dark-theme-white {
  background-color: #090c10 !important;
}

.dark-theme ::selection {
  color: #000;
  background: rgba(255, 255, 255, 0.85);
}

.dark-theme .card-body {
  background-color: #404040;
}

.dark-theme .card-header {
  background-color: #404040;
}

.dark-theme .MuiCardContent-root {
  background-color: #404040;
}

.dark-theme .metismenu {
  background-color: #404040;
}

/* ********************************************************************************************
    Design for a width of 1200px and 1250 between
*********************************************************************************************** */

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  .qr-code-box-image-container {
    width: 225px !important;
  }
}

/* ********************************************************************************************
    Design for a width of 768px and above
*********************************************************************************************** */

@media only screen and (min-width: 768px) {
  /* SM - Floating
    ======================================================================================= */
  .pull-sm-left {
    float: left !important;
  }

  .pull-sm-right {
    float: right !important;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .footer-top {
    padding: 20px 0px 20px;
  }
}

/* ********************************************************************************************
    Design for a width of 992px and above
*********************************************************************************************** */

@media only screen and (min-width: 992px) {
  /* MD - Floating
    ======================================================================================= */

  .pull-md-left {
    float: left !important;
  }

  .pull-md-right {
    float: right !important;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }
}

/* ********************************************************************************************
    Design for a width of 991px and below
*********************************************************************************************** */

@media only screen and (max-width: 992px) {
  body .sidebar ~ .content-wrapper {
    padding-left: 0px;
  }

  body .sidebar-left ~ .content-wrapper .content {
    margin-left: 0;
  }

  .top-toolbar .navbar-nav .nav-item.nav-text {
    padding: 0 10px;
  }

  body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a,
  body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a i {
    color: #617182;
  }

  body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a:active,
  body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a:active i,
  body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a:hover,
  body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a:hover i {
    color: #617182;
  }

  .nav-mobile-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    display: none;
  }

  .aside-left-open .nav-mobile-fixed {
    display: block;
  }

  .nav-mobile-fixed .navbar-toggler-icon {
    display: none;
  }

  .h-100p,
  .equal-height {
    height: 100%;
  }

  .input-group.w-250 {
    width: 200px !important;
  }

  .page-header .d-flex {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .bg-register-box {
    padding: 25px 30px !important;
  }

  .first-left-box.px-5.py-4 {
    padding: 0 !important;
  }

  .custom-stepper .MuiStepLabel-label {
    font-size: 15px;
  }

  .custom-stepper .MuiSvgIcon-root {
    height: 25px;
    width: 25px;
    font-size: 15px;
  }

  .banner-caption .heading {
    font-size: 50px;
  }

  .full-screen-banner {
    height: 50vh;
  }

  .logo-custom {
    margin-top: 15px;
    margin-left: 0;
  }

  .h-100p.no-gutters {
    height: 100%;
  }

  .editor-state-change {
    width: 90%;
  }
}

/* ********************************************************************************************
    Design for a width of 767px and below
*********************************************************************************************** */

@media only screen and (max-width: 767px) {
  .log-in .btn-success {
    height: 40px !important;
    font-size: 16px !important;
    padding: 8px 10px !important;
  }

  .m-b-90 {
    margin-bottom: 40px;
  }

  .tab-pane .actions {
    position: relative !important;
  }

  .tab-pane .actions.top-right {
    right: 0px !important;
  }

  .banner-caption .heading {
    font-size: 50px;
  }

  .MuiTabs-scroller {
    overflow-x: auto !important;
  }

  .MuiTabs-root .MuiButtonBase-root {
    padding: 12px 15px !important;
  }

  .text-big {
    font-size: 120px !important;
  }

  .bg-login-image .login-box {
    padding-top: 70px;
  }
}

/* ********************************************************************************************
    Design for a width of 576px and below
*********************************************************************************************** */

@media only screen and (max-width: 576px) {
  .d-flex,
  .media {
    flex-wrap: wrap !important;
  }

  .pagination {
    flex-wrap: wrap !important;
    margin-left: -13px !important;
    margin-top: 10px !important;
  }

  .d-flex.align-items-center .m-l-10 {
    margin-bottom: 8px !important;
  }

  .actions.top-right li {
    margin-bottom: 8px !important;
  }

  .actions.top-right .m-l-10 .input-group,
  .actions.top-right .ml-0 .input-group {
    width: 270px !important;
  }

  .left-form-content {
    width: 100%;
    float: none;
    text-align: center;
  }

  .accordion > .card > .card-header .btn-link {
    padding: 6px 32px 5px 20px;
  }

  .accordion > .card > .card-header {
    height: 70px !important;
    white-space: normal !important;
  }

  .page-content {
    padding: 15px !important;
  }

  .page-header {
    padding: 15px 15px 0;
  }
}

/* ********************************************************************************************
    Design for a width of 520px and below
*********************************************************************************************** */

@media only screen and (min-width: 576px) {
  .modal-custom .modal-dialog {
    max-width: 620px;
  }
}

/* ********************************************************************************************
    Design for a width of 410px and below
*********************************************************************************************** */

@media only screen and (max-width: 410px) {
  .site-logo li .brand-text {
    padding: 20px 0 0 0px;
  }

  .wizard-steps-panel {
    height: 17em;
  }

  .box-responsive {
    width: 120px !important;
  }

  .input-group.mb-3 {
    width: 250px !important;
  }

  .accordion > .card > .card-header {
    height: 90px !important;
    white-space: normal !important;
  }

  .accordion > .card > .card-header .btn-link {
    height: 90px !important;
    white-space: normal !important;
  }

  .banner-caption .heading {
    font-size: 35px;
  }

  .d-flex.bg-register-box,
  .main-container.d-flex {
    display: block !important;
  }

  .bg-register-box .form-group .w-350 {
    width: auto !important;
  }

  .bg-register-box .text-right.mt-auto .btn-primary {
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .css-1kqosj6-MuiChip-root {
    width: 200px;
    font-size: 11px !important;
  }
}
@media only screen and (max-width: 380px) {
  .qr-code-box-image-container {
    width: 200px !important;
  }
  .qr-code-box-image-container svg {
    height: 200px !important;
    width: 100% !important;
  }
}
@media only screen and (max-width: 280px) {
  .qr-code-box-image-container {
    width: 100px !important;
  }
  .qr-code-box-image-container svg {
    height: 100px !important;
  }
}
@media (max-height: 540px) {
  .modal-body {
    max-height: 50vh !important;
  }
}

.progress-hide {
  display: none;
}

.custom-progress {
  top: 6px;
  position: relative;
}

.custom-file-input:focus ~ .custom-file-label:after,
.custom-file-label,
.custom-file-label:after,
.form-control {
  background-color: transparent !important;
}
.react-viewer-list > li {
  width: 70px !important;
}
.react-viewer-list > li > img {
  width: 100px !important;
  height: 50px !important;
  margin-left: -15px !important;
  /* opacity: 0.5; */
}

.c-p {
  cursor: pointer;
}

.ticket-comment-attachment-url {
  font-size: 12px;
  border-bottom: 1px #6d6d6d solid;
}
.dark-theme .ticket-comment-attachment-url {
  color: #fff;
  font-size: 12px;
  border-bottom: 1px #6d6d6d solid;
}

.terms-and-conditions {
  font-size: 12px;
  color: #6d6d6d !important;
}

.dark-theme .terms-and-conditions {
  color: #fff !important;
}

/* login in button start */
.login-button {
  position: relative;
}

.login-button--loading::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
/* login in button end */

.show-password-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.declaration-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 256px));
  grid-template-rows: auto 2rem;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.declaration-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.declaration-signature {
  grid-area: 1 / 1 / 2 / 2;
}

.declaration-fullName-container {
  grid-area: 1 / 2 / 2 / 3;
}

.declaration-fullName-container > .declaration-fullName-text {
  overflow-wrap: anywhere;
  border-bottom: 1px solid #000;
}

.declaration-date-container {
  grid-area: 1 / 3 / 2 / 4;
}

.declaration-text-container {
  grid-area: 2 / 1 / 3 / 4;
}

.account-warningMessage {
  background-color: #0580c6;
  color: white;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.css-2gkwqb-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded:before {
  position: absolute !important;
  left: 0 !important;
  top: -1px !important;
  right: 0 !important;
  height: 1px !important;
  content: "" !important;
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  display: block !important;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}
.accordian-summary {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.accordian-question {
  margin: 0 8px;
}
.accordian-answer {
  margin-left: 40px;
}
.faq-spacing {
  margin-bottom: 20px;
}
.question-size {
  font-weight: 500 !important;
  color: black !important;
}
.category-spacing {
  margin-bottom: 10px;
}
.vps-select {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 2px 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  option {
    &:active {
      color: #000 !important;
    }
    &:checked {
      color: #000 !important;
    }
  }
}
.login-modal {
  .modal-dialog {
    max-width: 500px;
    width: 100%;
    margin: auto;
  }
  .modal-content {
    margin: 10px;
  }
  .modal-body {
    height: inherit;
  }
}
.pagelimit {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row !important;
  gap: 20px;
  margin-top: 10px !important;
}
.faq-icon {
  padding: 2px;
}

.show-password-register {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.dark-theme::-webkit-scrollbar-thumb {
  background-color: #858b91;
}
.dark-theme .modal-body::-webkit-scrollbar-thumb {
  background-color: #858b91;
}
.dark-theme .h-100p::-webkit-scrollbar-thumb {
  background-color: #858b91;
}
.dark-theme .card-body div::-webkit-scrollbar-thumb {
  background-color: #858b91;
}
.dark-theme .table-responsive::-webkit-scrollbar-thumb {
  background-color: #858b91;
}
.dark-theme .sidebar-left .main-menu::-webkit-scrollbar-thumb {
  background-color: #858b91;
}

.notification-icon {
  color: white;
  font-size: 28px;
  cursor: pointer;
}

.create-vps-warning-banner {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;
  color: red;
}

.cell-color-two-weeks {
  background-color: #ffa500;
}

.cell-color-one-weeks {
  background-color: #ff0000;
  color: #ffffff;
}

.term-and-conditions-body ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.term-and-conditions-body ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.term-and-conditions-body ol > li::before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

.term-and-conditions-body li ol > li {
  margin: 0;
}

.term-and-conditions-body li ol > li::before {
  content: counters(item, ".") " ";
}

.term-and-conditions-body p {
  margin-bottom: 5px;
}

.term-and-conditions-body .roman-order-list {
  counter-reset: roman-item;
}

.term-and-conditions-body .roman-order-list > li {
  counter-increment: roman-item;
  margin-bottom: 0.6em;
}

.term-and-conditions-body .roman-order-list > li::before {
  content: "(" counters(roman-item, ".", lower-roman) ")";
}

.notification-body {
  padding: 1rem;
}

.pricing-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.pricing-section {
  width: 100%;
  max-width: 1200px;
}

.pricing-header {
  text-align: center;
  margin-bottom: 24px;
}

.pricing-type {
  color: #00e676;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.pricing-title {
  color: #002b3b;
  font-size: 32px;
  font-weight: 700;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 16px;
  align-items: stretch;
}

.pricing-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  border: 1px solid #e5e7eb;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.pricing-card:hover {
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

.dark-theme .pricing-card:hover {
  box-shadow: 4px 4px 12px rgba(255, 255, 255, 0.7);
}

.pricing-card.popular {
  border: 2px solid #00df82;
  transform: scale(1.05);
  z-index: 1;
}

.popular-banner {
  background-color: #00df82;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 0;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
}

.pricing-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pricing-card.popular .pricing-content {
  padding-top: 36px;
}

.pricing-duration {
  padding: 16px;
  color: #002b3b;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.pricing-details {
  background-color: #2ac49e;
  color: white;
  padding: 12px;
  text-align: center;
}

.dark-theme .pricing-details {
  background-color: #002b3b;
}

.original-price {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  text-decoration: line-through;
}

.discounted-price {
  font-size: 24px;
  font-weight: 700;
  margin: 4px 0;
}

.discount-badge {
  background-color: white;
  border-radius: 999px;
  color: #002b3b;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 4px 12px;
  margin: 4px 0;
}

.saved-amount {
  font-size: 14px;
  color: #002b3b;
}

.dark-theme .saved-amount {
  font-size: 14px;
  color: unset;
}

.promo-label {
  color: #6b7280;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 8px;
}

.promo-footer {
  padding: 16px;
}

.promo-code-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #002b3b;
  border-radius: 6px;
  padding: 8px 12px;
  flex-wrap: nowrap;
  margin-top: auto;
}

.promo-code {
  color: white;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy-button {
  background-color: #00e676;
  border: none;
  border-radius: 4px;
  color: #002b3b;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 6px;
  transition: background-color 0.3s ease;
  flex-shrink: 0;
}

.copy-button:hover {
  background-color: #00c853;
}

.copy-button:active {
  background-color: #00af4c;
}

.chocoping-vps-discount-codes-page-description {
  font-size: 1.25rem;
  text-wrap: wrap;
  word-break: break-all;
}

.chocoping-vps-discount-codes-page-banner {
  background-color: #2ac49e;
  color: white;
  font-size: 3rem;
  padding: 1rem;
  text-align: center;
}
